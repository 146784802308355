import React, { useState } from "react";
import { Divider, Header, Button, Modal, Icon } from "semantic-ui-react";
import MaintenanceForm from "../../Containers/Forms/NewMaintenanceForm";

import { colors } from "../../Styles";

//Controls displaying and creating notes.
//Required props:
// noteParentType - type of note; can be lease_id or unit_id
// noteParentId - the id of the parent that the note belongs to
// headerTitle - the header title of the note section
const NewMaintenanceModal = ({ headerTitle, leaseId }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <Header>
        <Header.Content>Maintenance Requests</Header.Content>
        {leaseId && (
          <Button
            style={colors.actionButton}
            compact
            floated="right"
            onClick={openModal}
          >
            <Icon name="plus" />
            Request
          </Button>
        )}
      </Header>
      <Divider />
      <Modal
        style={styles.modal}
        open={isModalOpen}
        onClose={closeModal}
        onOpen={openModal}
        content={<MaintenanceForm leaseId={leaseId} closeModal={closeModal} />}
      />
    </>
  );
};

const styles = {
  modal: {
    paddingLeft: "2rem",
    paddingRight: "2em",
    paddingBottom: "2em",
  },
};

export default NewMaintenanceModal;
