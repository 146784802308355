import axios from "axios";
import {
  LOADING,
  LOADING_LEASE,
  LOADING_UNIT,
  LOADING_PROPERTY,
  LOADING_MAINTENANCE_REQUEST,
  LOADING_NOTES,
  LOADING_LEASE_REQUEST,
  LOADING_TENANT,
} from "./types";

// export const api = axios.create({
//   baseURL: "http://localhost:4741",
// });

export const api = axios.create({
  baseURL: "https://sheltered-tundra-66086.herokuapp.com",
  headers: {
    "Content-Type": "application/json",
  },
});

export const userSignIn = (credentials) => async (dispatch) => {
  dispatch({ type: LOADING });

  return await api.post("/login", { credentials });
};

export const userSignOut = () => async (dispatch, getState) => {
  const { id, token } = getState().auth;

  dispatch({ type: LOADING });

  return await api.delete(`/sign-out/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const userSignUp = (credentials) => async (dispatch) => {
  dispatch({ type: LOADING });

  return await api.post("/sign-up", { credentials });
};

//PROPERTIES//////////////////////////
export const fetchUserProperties = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_PROPERTY,
  });

  const token = getState().auth.token;
  return await api.get(
    "/properties",

    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const fetchSelectedProperty = (id) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_PROPERTY,
  });

  const token = getState().auth.token;
  return await api.get(`/properties/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateProperty = (property) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_PROPERTY,
  });

  const token = getState().auth.token;
  return await api.put(
    `/properties/${property.id}`,
    { property },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const postNewProperty = (property) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_PROPERTY,
  });

  const token = getState().auth.token;
  return await api.post(
    "/properties",
    { property },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deleteProperty = (id) => async (dispatch, getState) => {
  const token = getState().auth.token;

  dispatch({ type: LOADING_PROPERTY });

  return await api.delete(`/properties/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//UNIT///////////////////////////

export const fetchUserUnits = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_UNIT,
  });
  const token = getState().auth.token;
  return await api.get("/units", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchUnitDetails = (id) => async (dispatch, getState) => {
  const token = getState().auth.token;

  dispatch({ type: LOADING_UNIT });

  return await api.get(`/units/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postNewUnit = (unit) => async (dispatch, getState) => {
  const token = getState().auth.token;

  dispatch({
    type: LOADING_UNIT,
  });

  return await api.post(
    "/units",
    { unit },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateUnit = (unit) => async (dispatch, getState) => {
  const token = getState().auth.token;

  dispatch({
    type: LOADING_UNIT,
  });

  const response = await api.put(
    `/units/${unit.id}`,
    { unit },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deleteUnit = (id) => async (dispatch, getState) => {
  const token = getState().auth.token;
  dispatch({ type: LOADING_UNIT });

  return await api.delete(`/units/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchActiveUnits = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_UNIT });

  const token = getState().auth.token;

  return await api.get(`/units/active`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//LEASE///////////////////////////////\

export const fetchTenantLeases = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_LEASE,
  });

  const token = getState().auth.token;

  return await api.get("/leases/tenant_leases", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchTenantActiveLease = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_LEASE });
  const token = getState().auth.token;

  return await api.get("/leases/tenant_current_lease", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchActiveLeases = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_LEASE });

  const token = getState().auth.token;

  return await api.get(`/leases/active`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchUnitLeases = (id) => async (dispatch, getState) => {
  const token = getState().auth.token;

  dispatch({ type: LOADING_LEASE });

  return await api.get(`/leases?unit_id=${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchAllLeases = () => async (dispatch, getState) => {
  const token = getState().auth.token;

  return await api.get(`/leases`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchLeaseDetails = (id) => async (dispatch, getState) => {
  const token = getState().auth.token;

  dispatch({ type: LOADING_LEASE });

  return await api.get(`/leases/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postNewLease = (lease) => async (dispatch, getState) => {
  dispatch({ type: LOADING_LEASE });

  const token = getState().auth.token;

  return await api.post(
    "/leases",
    { lease },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateLease = (lease) => async (dispatch, getState) => {
  const token = getState().auth.token;

  dispatch({ type: LOADING_LEASE });

  return await api.put(
    `/leases/${lease.id}`,
    { lease },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const cancelActiveLease = (lease) => async (dispatch, getState) => {
  const token = getState().auth.token;

  dispatch({ type: LOADING_LEASE });

  return await api.put(
    `/leases/${lease.id}`,
    { lease },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const createActiveLease = (currentLease) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token;

  dispatch({ type: LOADING_LEASE });

  const lease = { active: true };
  const response = await api.put(
    `/leases/${currentLease.id}`,
    { lease },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

//MAINTENANCE REQUEST/////////////////////////////

export const postNewMaintenance = (request) => async (dispatch, getState) => {
  //
  const token = getState().auth.token;

  dispatch({ type: LOADING_MAINTENANCE_REQUEST });

  return await api.post(
    "/maintenance_requests",
    { maintenance_request: request },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateMaintenanceRequest = (data) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token;

  dispatch({ type: LOADING_MAINTENANCE_REQUEST });

  return await api.put(
    `/maintenance_requests/${data.id}`,
    { maintenance_request: data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const fetchMaintenanceForLease = (leaseId) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token;

  dispatch({ type: LOADING_MAINTENANCE_REQUEST });

  return await api.get(`/maintenance_requests`, {
    params: {
      lease_id: leaseId,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchAllActiveMaintenanceRequests = () => async (
  dispatch,
  getState
) => {
  dispatch({ type: LOADING_MAINTENANCE_REQUEST });
  const token = getState().auth.token;
  return await api.get(`/maintenance_requests/active`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//NOTE/////////////////////////////////////////

export const fetchUnitNotes = (type, id) => async (dispatch, getState) => {
  const token = getState().auth.token;

  return await api.get(`/notes`, {
    params: {
      [type]: id,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postNewNote = (note) => async (dispatch, getState) => {
  const token = getState().auth.token;
  dispatch({ type: LOADING_NOTES });

  return await api.post(
    "/notes",
    { note },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deleteNote = (id) => async (dispatch, getState) => {
  const token = getState().auth.token;

  dispatch({ type: LOADING_NOTES });

  return await api.delete(`/notes/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//EDIT PROFILE///////////////
export const updateTenantProfile = (profile) => async (dispatch, getState) => {
  const token = getState().auth.token;
  dispatch({ type: LOADING_TENANT });

  return await api.put(
    `/tenant_profiles/${profile.id}`,
    { tenant_profile: profile },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

//TENANT//////////////////
export const fetchAllTenants = () => async (dispatch, getState) => {
  const token = getState().auth.token;

  dispatch({ type: LOADING_TENANT });

  return await api.get("/tenant_profiles", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // dispatch({
  //   type: FETCH_ALL_TENANTS,
  //   payload: response.data.tenant_profiles,
  // });
};

export const fetchTenant = (id) => async (dispatch, getState) => {
  const token = getState().auth.token;

  dispatch({ type: LOADING_TENANT });

  return await api.get(`/tenant_profiles/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteTenant = (id) => async (dispatch, getState) => {
  const token = getState().auth.token;

  dispatch({ type: LOADING_TENANT });

  return await api.delete(`/tenant_profiles/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//LEASE REQUESTS//////////////////////////////
export const fetchPendingLeaseRequests = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_LEASE_REQUEST });

  const token = getState().auth.token;

  return await api.get("/lease_requests/pending", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteLeaseRequest = (id) => async (dispatch, getState) => {
  dispatch({ type: LOADING_LEASE_REQUEST });

  const token = getState().auth.token;

  return await api.delete(`/lease_requests/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateLeaseRequest = (lease_request) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token;

  dispatch({ type: LOADING_LEASE_REQUEST });

  return await api.put(
    `/lease_requests/${lease_request.id}`,
    { lease_request },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
