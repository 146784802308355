import React from "react";

import { Card, Button, Header } from "semantic-ui-react";

import { Link } from "react-router-dom";
import Moment from "react-moment";

const LeaseRequestCard = ({ request, updateLeaseRequest }) => {
  const {
    id,
    address,
    landlord_name,
    lease_num,
    start_date,
    end_date,
    rent,
    property,
    unit_number,
    unit_id,
  } = request.lease;
  // const { unit_num, bed_num, bath_num } = request.lease.unit;
  // const { first_name, last_name } = request.lease.landlord_profile;

  const leaseTimer = <Moment to={start_date} />;
  const leaseDuration = <Moment duration={start_date} date={end_date} />;
  const startDate = <Moment format="MMM DD, YYYY" date={start_date} />;
  const endDate = <Moment format="MMM DD, YYYY" date={end_date} />;

  const handleUpdate = (answer) => {
    return updateLeaseRequest({ ...request, accepted: answer });
  };

  return (
    <Card raised fluid>
      <Card.Content>
        <Header floated="left">
          <Header.Subheader content={address} />
          <Header.Content content={`Unit# ${unit_number}`} />
        </Header>
        <Link
          to={`/tenant/lease-requests/review-lease/${id}`}
          style={{ float: "right" }}
        >
          VIEW DETAILS
        </Link>
      </Card.Content>

      <Card.Content>
        <Card.Group doubling textAlign="center" itemsPerRow={4}>
          <Card>
            <Card.Content textAlign="center">
              <Card.Meta>Start Date - End Date</Card.Meta>
              <Card.Description>
                {startDate} - {endDate}
              </Card.Description>
            </Card.Content>
          </Card>

          <Card>
            <Card.Content>
              <Card.Meta>Lease Duration</Card.Meta>
              <Card.Description>{leaseDuration}</Card.Description>
            </Card.Content>
          </Card>

          <Card>
            <Card.Content>
              <Card.Meta>Monthly Rent</Card.Meta>
              <Card.Description>${rent}</Card.Description>
            </Card.Content>
          </Card>

          <Card>
            <Card.Content>
              <Card.Meta>Landlord</Card.Meta>
              <Card.Description>{landlord_name}</Card.Description>
            </Card.Content>
          </Card>
        </Card.Group>
      </Card.Content>

      <Card.Content extra>
        <Button className="negative" onClick={() => handleUpdate(false)}>
          Decline
        </Button>
        <Button onClick={() => handleUpdate(true)}>Accept</Button>
      </Card.Content>
    </Card>
  );
};

export default LeaseRequestCard;
