import React from "react";
import { Card, Label, Icon, Header } from "semantic-ui-react";

import { Link } from "react-router-dom";

//Unit - unit object
//showAddress - bool
const UnitCard = ({ unit, showAddress, propertyId }) => (
  <Card
    as={Link}
    to={`/landlord/properties/${unit.property.id}/units/${unit.id}`}
  >
    {!unit.active_lease_id && (
      <Label color="red" corner="right" icon="attention" />
    )}

    <Card.Content>
      <Card.Meta>{unit.property.name}</Card.Meta>
      <Card.Header>Unit: {unit.unit_num}</Card.Header>
    </Card.Content>

    {showAddress && (
      <Card.Content extra>
        <Card.Meta>{unit.property.address}</Card.Meta>
      </Card.Content>
    )}

    <Card.Content extra>
      <Label>
        <Icon name="bed" />
        {unit.bed_num}
      </Label>
      <Label>
        <Icon name="bath" />
        {unit.bath_num}
      </Label>
    </Card.Content>
  </Card>
);

export default UnitCard;
