import React, { useState } from "react";

import Moment from "react-moment";
import { Link, useRouteMatch } from "react-router-dom";

import {
  Header,
  Button,
  Select,
  Card,
  Modal,
  Segment,
  Icon,
} from "semantic-ui-react";
import LeaseCard from "../LeaseCard";
import NewNoteModal from "../Modals/NewNotes";
import NoteCard from "../NoteCard";
import MoreOptions from "../OptionsDropdown";
import AddNewTenantForm from "../../Containers/Forms/AddTenant";
import TerminationForm from "../../Containers/Forms/TerminationReason";

import ConfirmationModal from "../Modals/ConfirmationBox";

const ActiveLease = ({ activeLease, deleteNoteAction }) => {
  const match = useRouteMatch();

  const actionMenuOptions = [
    { icon: "user plus", text: "Add Tenant", action: () => openTenantModal() },
    {
      icon: "delete",
      text: "Terminate",
      action: () => openConfirmModal(),
      style: { backgroundColor: "red", color: "white" },
    },
  ];

  const [isModalOpen, setModalOpen] = useState({
    tenantModal: false,
    confirmModal: false,
  });

  const handleDeleteAction = (note) => deleteNoteAction(note.id, "lease");

  const openTenantModal = () => setModalOpen({ tenantModal: true });
  const closeTenantModal = () => setModalOpen({ tenantModal: false });
  const openConfirmModal = () => setModalOpen({ confirmModal: true });
  const closeConfirmModal = () => setModalOpen({ confirmModal: false });

  const { start_date, end_date, property, unit, tenant_profiles } = activeLease;

  return (
    <>
      <Header as="h3">Lease Agreement</Header>

      <Card className="ui fluid">
        {activeLease.active && (
          <Card.Content>
            <MoreOptions options={actionMenuOptions} />
            <Header>
              Expires: <Moment to={activeLease.end_date}>{Date.now()}</Moment>
            </Header>
          </Card.Content>
        )}
        <LeaseCard lease={activeLease} isActive={true} />
      </Card>

      <Modal
        open={isModalOpen.tenantModal}
        onClose={closeTenantModal}
        onOpen={openTenantModal}
      >
        <Header
          textAlign="center"
          content="Add Tenants to this Lease"
          dividing
        />
        <AddNewTenantForm lease={activeLease} closeModal={closeTenantModal} />
      </Modal>

      <Modal
        open={isModalOpen.confirmModal}
        onClose={closeConfirmModal}
        content={
          <TerminationForm
            activeLease={activeLease}
            closeModal={closeConfirmModal}
          />
        }
      />
    </>
  );
};

export default ActiveLease;
