import React from "react";
import { Link } from "react-router-dom";

import { Card, Icon, Button, Item, Label, Header } from "semantic-ui-react";

import UnitCard from "./UnitCard";

const PropertyCard = ({ children, property, handleDeleteProperty }) => {
  const { address, id, name } = property;

  const styles = {
    centerAndOpposite: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    rightAlign: {
      marginLeft: "auto",
    },
    vacantTag: {
      marginLeft: "1rem",
    },
  };

  return (
    <Card className="ui fluid">
      <Card.Content className="property-card header">
        <Button
          as={Link}
          className="action-button"
          to={`/landlord/properties/${id}/add-unit-form`}
          content="Unit"
          icon="plus"
          style={{ float: "right" }}
          labelPosition="left"
        />

        <Header
          as={Link}
          to={`properties/${property.id}`}
          style={{
            display: "inline-flex",
            alignItems: "center",
            margin: 0,
          }}
        >
          <Icon name="building" />

          {name ? (
            <div>
              <Header.Content>{name}</Header.Content>
              <Header.Subheader>{address}</Header.Subheader>
            </div>
          ) : (
            <Header.Content>{address}</Header.Content>
          )}
        </Header>
      </Card.Content>
      <Card.Content>{children}</Card.Content>
    </Card>
  );
};

export default PropertyCard;
