import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserUnits } from "../../actions";

import { Switch, Route } from "react-router-dom";

import { Container, Grid } from "semantic-ui-react";

import SideNavbar from "../../Components/SideNavBar";
import Properties from "../LandlordSide/Properties/AllProperties";
import PropertyDetails from "../LandlordSide/Properties/PropertyOverview";
import AllUnits from "./AllUnits";
// import UnitDetails from '../UnitDetails';
import UnitDetails from "../LandlordSide/Unit/UnitOverView";
import PropertyForm from "../Forms/NewPropertyForm";
import UnitForm from "../Forms/NewUnitForm";
import LeaseForm from "../Forms/NewLeaseForm";
import Feed from "./Feed";
import Account from "./Account";
import FABMenu from "../../Components/FABMenu";

import PopUpMessage from "../../Components/PopUpMessages";

function LandlordLanding(props) {
  const dispatch = useDispatch();

  //FETCH ALL USER UNITS ON LANDLORD ROOT
  useEffect(() => {
    dispatch(getUserUnits());
  }, [dispatch]);

  const popUpMessage = useSelector((state) => state.popUpMessage);

  return (
    <Container>
      <Grid>
        <Grid.Column width={2} only="computer">
          <SideNavbar />
        </Grid.Column>
        <Grid.Column width={3} only="tablet">
          <SideNavbar />
        </Grid.Column>
        <FABMenu />
        <Grid.Column computer={13} mobile={16} tablet={12}>
          <Switch>
            <Route path="/landlord/feed" component={Feed} />
            <Route path="/landlord/account" component={Account} />
            <Route
              path="/landlord/properties/:property_id/units/:unit_id/new-lease-form"
              component={LeaseForm}
            />
            <Route
              path={`/landlord/properties/:property_id/units/:unit_id`}
              component={UnitDetails}
            />
            <Route
              path="/landlord/properties/:property_id/add-unit-form"
              component={UnitForm}
            />

            <Route
              path="/landlord/properties/:property_id"
              component={PropertyDetails}
            />

            <Route path="/landlord/properties" component={Properties} />

            <Route path="/landlord/units/:id" component={UnitDetails} />
            <Route path="/landlord/units" component={AllUnits} />
            <Route
              path="/landlord/add-property-form"
              component={PropertyForm}
            />
          </Switch>
        </Grid.Column>
      </Grid>
    </Container>
  );
}

export default LandlordLanding;
