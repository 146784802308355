import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Container, Icon } from "semantic-ui-react";
import PopUpMessage from "./PopUpMessages";

function NavigationBar({ auth, handleUserSignOut }) {
  const [state, setState] = useState({});

  const { isSignedIn, landlord_profile, tenant_profile } = auth;

  // const handleItemClick = (e, { name }) => setState({ activeItem: name });

  const { activeItem } = state;

  const renderDashboardLink = !isSignedIn ? (
    ""
  ) : landlord_profile ? (
    <Menu.Item as={Link} to="/landlord/feed" name="Landlord Feed">
      Feed
    </Menu.Item>
  ) : (
    <Menu.Item as={Link} to="/tenant/active-lease" name="Tenant active lease">
      Dashboard
    </Menu.Item>
  );

  return (
    <Menu container={"true"} secondary className="nav-bar">
      <Container>
        <Menu.Item as={Link} to="/" name="home">
          RENZY
        </Menu.Item>

        <Menu.Menu position="right">
          <Menu.Item
            style={isSignedIn ? style.hidden : {}}
            as={Link}
            to="/signIn"
            name="signInPage"
          >
            Sign In
          </Menu.Item>
          {renderDashboardLink}
        </Menu.Menu>
      </Container>
    </Menu>
  );
}

const style = {
  hidden: {
    display: "none",
  },
};

export default NavigationBar;
