import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  terminateLease,
  updateLease,
  removeLeaseNote,
  getLeaseDetails,
  clearActiveLease,
} from "../../../actions";

import { Switch, Route, Link } from "react-router-dom";

import Loading from "../../../Components/Loading";
import ActiveLeaseView from "../../../Components/unitDetails/ActiveLease";
import LeaseNotes from "./LeaseNotes";
import NoActiveLeaseCard from "../../../Components/NoActiveLeaseCard";

const ActiveLeaseTab = ({ match, location }) => {
  const { url, params } = match;

  const dispatch = useDispatch();

  const unit = useSelector((state) => state.units.selectedUnit);

  const activeLease = useSelector((state) => state.leases.lease);

  const loadingLease = useSelector((state) => state.leases.isLoading);

  const { pathname } = location;

  useEffect(() => {
    if (unit.active_lease_id) {
      dispatch(getLeaseDetails(unit.active_lease_id));
    }
  }, [dispatch, unit]);

  const cancelLeaseAction = (data) => dispatch(terminateLease(data));

  const deleteNoteAction = (id) => dispatch(removeLeaseNote(id));

  return loadingLease ? (
    <Loading nameOfResource="Active Lease" message="Please wait..." />
  ) : Object.values(activeLease).length ? (
    <>
      <ActiveLeaseView activeLease={activeLease} />

      <LeaseNotes activeLease={activeLease} />
    </>
  ) : (
    <NoActiveLeaseCard />
  );
};

export default ActiveLeaseTab;
