import React from "react";
import { Card, Button, Header, Label, Icon } from "semantic-ui-react";

import Moment from "react-moment";

const MaintenanceCard = ({ tix, completeAction }) => {
  return (
    <Card className="ui fluid" key={tix.id}>
      <Card.Content>
        {tix.active && (
          <div>
            <Button
              icon
              className="complete-maintenance"
              labelPosition="right"
              style={{ float: "right" }}
              onClick={() => completeAction(tix)}
            >
              <Icon name="check" className="confirm-button" />
              Complete
            </Button>
          </div>
        )}
        <div>
          Created: <Moment format="MM/DD/YYYY" date={tix.created_at} />
        </div>
        {!tix.active && (
          <div style={{ color: "green" }}>
            Completed: <Moment format="MM/DD/YYYY" date={tix.date_completed} />
          </div>
        )}
      </Card.Content>
      <Card.Content>
        <Header sub>
          {tix.title}
          {tix.urgent && tix.urgent && (
            <Label horizontal color="red" content="Urgent" />
          )}
        </Header>
        {tix.description}
      </Card.Content>
      <Card.Content extra>Submitted By: {tix.creator}</Card.Content>
    </Card>
  );
};

export default MaintenanceCard;
