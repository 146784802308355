import React, { useState } from "react";
import { Divider, Header, Button, Modal, Icon } from "semantic-ui-react";
import NoteForm from "../../Containers/Forms/NewNoteForm";

import { colors } from "../../Styles";

//Controls displaying and creating notes.
//Required props:
// noteParentType - type of note; can be lease_id or unit_id
// noteParentId - the id of the parent that the note belongs to
// headerTitle - the header title of the note section
const NewNoteModal = ({
  noteParentType,
  noteParentId,
  headerTitle,
  loading,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <Header>
        <Header.Content>{headerTitle}</Header.Content>
        <Button
          style={colors.actionButton}
          compact
          floated="right"
          onClick={openModal}
        >
          <Icon name="plus" />
          Note
        </Button>
      </Header>
      <Divider />
      <Modal
        style={styles.modal}
        open={isModalOpen}
        onClose={closeModal}
        onOpen={openModal}
        content={
          <NoteForm
            noteParentType={`${noteParentType}`}
            noteParentId={noteParentId}
            closeModal={closeModal}
            loading={loading}
          />
        }
      />
    </>
  );
};

const styles = {
  modal: {
    paddingLeft: "2rem",
    paddingRight: "2em",
    paddingBottom: "2em",
  },
};

export default NewNoteModal;
