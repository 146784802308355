import React, { Component } from "react";
import { reduxForm, Field, destroy, SubmissionError } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { getAllTenants, terminateLease } from "../../actions";

import { Form, Button, Header, Divider, List } from "semantic-ui-react";

import FormConfirmation from "./FormConfirmation";

//No hooks for redux-forms; must use HOC

class TerminationReason extends Component {
  state = { page: 1 };

  renderError = ({ error, touched }) => {
    if (error && touched) {
      return {
        content: error,
        pointing: "above",
      };
    }
  };

  renderInput = ({ placeholder, label, type, input, meta }) => {
    return (
      <Form.Input
        {...input}
        error={this.renderError(meta)}
        label={label}
        placeholder={placeholder}
        value={input.value}
      />
    );
  };

  nextPage = () => this.setState({ page: this.state.page + 1 });

  previousPage = () => this.setState({ page: this.state.page - 1 });

  terminate = (formData) => {
    const { property_id, unit_id } = this.props.match.params;

    const updatedForm = {
      ...formData,
      id: this.props.activeLease.id,
      termination_date: new Date(),
      active: false,
    };

    return (
      this.props
        .terminateLease(updatedForm)
        // .then(() => this.props.closeModal())
        .then(() =>
          this.props.history.push(
            `/landlord/properties/${property_id}/units/${unit_id}/active-lease`
          )
        )
        .catch((error) => {
          throw new SubmissionError(error);
        })
    );
  };

  render() {
    const { page } = this.state;
    const { activeLease } = this.props;
    return (
      <>
        <Header>Cancel Lease for {activeLease.address}</Header>
        <Divider hidden />

        <div className="content">
          <List>
            <List.Item>
              <List.Content>Lease ID: {activeLease.lease_num}</List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                Period: {activeLease.start_date} - {activeLease.end_date}
              </List.Content>
            </List.Item>
          </List>
          <Form onSubmit={this.props.handleSubmit(this.terminate)}>
            {page === 1 && (
              <>
                <Field
                  name="termination_reason"
                  component={this.renderInput}
                  placeholder="Please explain"
                  label="Reason for termmination"
                  type="text"
                />
                <Button
                  disabled={this.props.invalid ? true : false}
                  onClick={() => this.nextPage()}
                  content="Next"
                  icon="right arrow"
                  labelPosition="right"
                  floated="right"
                  style={{ marginTop: "1rem", marginBottom: "1.5rem" }}
                />
              </>
            )}

            {page === 2 && (
              <FormConfirmation
                previousPage={this.previousPage}
                formName="TerminationReason"
                submitButtonName="Terminate"
                formHeader="Terminate Lease"
                formObj={this.props.formObj}
              />
            )}
          </Form>
        </div>
      </>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.termination_reason) {
    errors.termination_reason = "Please describe the reason for termination";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    formObj: state.form.TerminationReason,
    // lease: state.leases.activeLease,
    // loading: state.properties.loading
  };
};

const submitSuccessCallback = (result, dispatch) => {
  // this.props.closeModal();
  return dispatch(destroy("TerminationReason"));
};

const wrappedForm = reduxForm({
  form: "TerminationReason",
  destroyOnUnmount: false,
  validate,
  onSubmitSuccess: submitSuccessCallback,
})(TerminationReason);

const connectedForm = connect(mapStateToProps, {
  terminateLease,
  getAllTenants,
})(wrappedForm);

export default withRouter(connectedForm);
