import React from "react";
import { useDispatch } from "react-redux";
import { deleteNote } from "../actions";
import { Message, List, Card, Button, Icon } from "semantic-ui-react";

// import NewNoteModal from './Modals/NewNotes';

//Controls displaying, creating notes, and deleting notes.
//Required props:
// notes - all notes to be displayed
// type - the type of note; unit, lease, or property
const NoteCard = ({ note, deleteNoteAction }) => {
  const dispatch = useDispatch();

  return (
    <List.Item key={note.id}>
      <List.Content className="note-item-content">
        <div>
          <List.Header>{note.title} </List.Header>
          <List.Description>{note.description}</List.Description>
        </div>
        <Button
          className="negative"
          name="trash"
          icon="trash"
          onClick={() => deleteNoteAction(note.id)}
        />
      </List.Content>
    </List.Item>
  );
};

export default NoteCard;
