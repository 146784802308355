import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserUnits } from "../../actions";

import { Card, Header, Divider, Message } from "semantic-ui-react";

import AddressField from "../Forms/AddressField";
import UnitCard from "../../Components/UnitCard";
import Loading from "../../Components/Loading";

const AllUnits = () => {
  const units = useSelector((state) => Object.values(state.units.userUnits));
  const isLoading = useSelector((state) => state.units.isLoading);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getUserUnits());
  // }, [dispatch]);

  const noResource = (header, content) => (
    <Message icon="cogs" header={header} content={content} />
  );

  const renderActiveUnit = () => {
    const activeUnits = units.filter((unit) => unit.active_lease_id);

    return activeUnits.length === 0 ? (
      <Message icon="cogs" header="No active units to display" />
    ) : (
      activeUnits.map((unit) => {
        return <UnitCard unit={unit} showAddress={true} />;
      })
    );
  };

  const renderInactiveUnits = () => {
    const inactiveUnits = units.filter((units) => !units.active_lease_id);

    return inactiveUnits.length === 0 ? (
      <Message icon="cogs" header="No vacant units to display" />
    ) : (
      inactiveUnits.map((unit) => {
        return <UnitCard unit={unit} showAddress={true} />;
      })
    );
  };

  const loading = <Loading nameOfResource="Units" />;

  return (
    <>
      <Header content="Active Units" />
      <Divider />
      {isLoading ? (
        <Loading nameOfResource="Units" />
      ) : (
        <>
          {" "}
          <Card.Group doubling itemsPerRow={3}>
            {renderActiveUnit()}
          </Card.Group>
          <br />
          <Header className="red-text" content="Vacant Units" />
          <Divider />
          <Card.Group itemsPerRow={3} doubling>
            {renderInactiveUnits()}
          </Card.Group>
        </>
      )}
    </>
  );
};

export default AllUnits;
