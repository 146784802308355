import React from "react";
import { useSelector } from "react-redux";
import { Divider, Header, Icon, Table, Button } from "semantic-ui-react";

const FormConfirmation = (props) => {
  const {
    formName,
    previousPage,
    submitButtonName,
    formHeader,
    formObj,
    loading,
  } = props;

  const allTenants = useSelector((state) => state.tenants.tenants);

  //Formats feild keys to display for user to see on form confirmation
  const formatFieldKeys = (key) => {
    let formattedKey = key;

    switch (key) {
      case "unit_num":
        return (formattedKey = "Unit Number");
      case "bed_num":
        return (formattedKey = "Bedrooms");
      case "bath_num":
        return (formattedKey = "Bathrooms");
      case "parking_num":
        return (formattedKey = "Parking Spots");
      case "start_date":
        return (formattedKey = "Start Date");
      case "end_date":
        return (formattedKey = "End Date");
      case "rent":
        return (formattedKey = "Rent Amount");
      case "security_deposit":
        return (formattedKey = "Security Deposit");
      case "last_month_deposit":
        return (formattedKey = "Last Month's Deposit");
      case "tenant_profiles_ids":
        return (formattedKey = "Verified Tenants");
      case "tenant_profiles_attributes":
        return (formattedKey = "Tenants");
      case "first_name":
        return (formattedKey = "First Name");
      case "last_name":
        return (formattedKey = "First Name");
      // case "tenant_name":
      //   return (formattedKey = "Unregistered Tenant");
      default:
        return (formattedKey = key);
    }
  };

  //Formats values for user to see on formconfirmation.
  //ie. instead of displaying 'tenant_profile_id', it will display tenants name instead

  // IDEA: Add default values directly to input in main forms. That will avoid using this funciton to format values
  const formatValue = (key, value) => {
    console.log("IN SWITCH", value);
    switch (key) {
      case "urgent":
        return value ? (value = "True") : (value = "False");

      case "tenant_profiles_ids":
        if (value.length) {
          console.log("TENANT VALUE", value);

          return value.map((tenant) => {
            const tenantProfile = Object.values(allTenants).filter(
              (tenantProfile) => {
                return tenantProfile.id === tenant.profile_id;
              }
            );

            console.log("TENANT PROFILE", tenantProfile);

            return {
              first_name: tenantProfile[0].first_name,
              last_name: tenantProfile[0].last_name,
            };
          });

          // return (value = `${tenant[0].first_name} ${tenant[0].last_name}`);
        }

      case "tenant_profiles_attributes":
        if (value.length) {
          return value.map((eachTenant) => {
            return {
              [`${formatFieldKeys("first_name")}`]: eachTenant.first_name,
              [`${formatFieldKeys("last_name")}`]: eachTenant.last_name,
            };
          });

          // const tenant = Object.values(allTenants).filter(
          //   (tenant) => tenant.id === value
          // );
          //
          // return (value = `${tenant[0].first_name} ${tenant[0].last_name}`);
        }

      default:
        return value ? (value = value) : (value = "none");
    }
  };

  const renderContent = () => {
    const { values, fields } = formObj;

    //Takes all form fields and values to message into readable confirmation form to dispaly to user before submitting
    return Object.keys(fields).map((key) => {
      let formattedKey = formatFieldKeys(key);
      let formattedValue = formatValue(key, values[key]);

      return (
        <Table.Row key={key} style={styles.capitalize}>
          <Table.Cell width={6}>{formattedKey}</Table.Cell>
          {key === "tenant_profiles_attributes" ? (
            <Table.Cell>
              {values[key].map((tenantObj) => (
                <p>
                  {tenantObj.first_name} {tenantObj.last_name}
                </p>
              ))}
            </Table.Cell>
          ) : key === "tenant_profiles_ids" ? (
            <Table.Cell>
              {formattedValue.map((tenantObj) => {
                console.log("TENANT ONJ", tenantObj);
                return (
                  <p>
                    {tenantObj.first_name} {tenantObj.last_name}
                  </p>
                );
              })}
            </Table.Cell>
          ) : (
            <Table.Cell>{formattedValue}</Table.Cell>
          )}
        </Table.Row>
      );
    });
  };

  const styles = {
    capitalize: {
      textTransform: "capitalize",
    },
  };

  return (
    <>
      <Divider horizontal>
        <Header as="h4">Confirmation</Header>
      </Divider>

      <p>Please confirm that all information is correct before submitting.</p>

      <Divider horizontal>
        <Header as="h4">
          <Icon name="bar chart" />
          {props.formHeader}
        </Header>
      </Divider>

      <Table definition unstackable>
        <Table.Body>{renderContent()}</Table.Body>
      </Table>
      <Button
        className="edit"
        onClick={() => previousPage()}
        content="Edit"
        icon="left arrow"
        labelPosition="left"
      />
      <Button
        loading={loading}
        content={submitButtonName}
        floated="right"
        labelPosition="right"
        icon="arrow alternate circle right"
      />
    </>
  );
};

export default FormConfirmation;
