import React, { useState, useEffect } from "react";
import {
  getTenant,
  editTenantProfile,
  revokeLeaseRequest,
  removeCustomTenant,
} from "../actions";
import { submit, SubmissionError } from "redux-form";

import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Table,
  Button,
  Segment,
  Header,
  Icon,
  Label,
  Modal,
} from "semantic-ui-react";

import Moment from "react-moment";
import ProfileView from "./ProfileView";
import ProfileEditForm from "../Containers/Forms/ProfileEditForm";

import { Link } from "react-router-dom";

const LeaseCard = ({ lease }) => {
  const landlord = lease.landlord_profile;
  const { isLandlord } = useSelector((state) => state.auth);

  const [profile, setProfile] = useState({});
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const closeModal = () => {
    setIsEditing(false);
    setModalOpen(false);
  };

  const handleDeleteRequest = (id) => {
    return dispatch(revokeLeaseRequest(id)).then(() => setModalOpen(false));
  };

  const handleDeleteTenant = (id) => {
    return dispatch(removeCustomTenant(id)).then(() => setModalOpen(false));
  };

  const handleProfileSubmit = (profile) => {
    return dispatch(editTenantProfile(profile))
      .then(() => setProfile(profile))
      .then(() => setIsEditing(false))
      .catch((error) => {
        throw new SubmissionError(error);
      });
  };

  const renderedTenants =
    lease.tenant_profiles.length !== 0 &&
    lease.tenant_profiles.map((tenant) => {
      if (tenant.unique_number) {
        return (
          <Label
            key={tenant.id}
            as={"a"}
            className="verified profile"
            onClick={() => {
              tenant.type = "Registered Tenant";
              setProfile(tenant);
              setModalOpen(true);
            }}
          >
            <Icon name="user" />
            {tenant.first_name} {tenant.last_name}
            <Label.Detail>Verified</Label.Detail>
          </Label>
        );
      } else {
        return (
          <Label
            key={tenant.id}
            as={"a"}
            className="custom profile"
            onClick={() => {
              tenant.type = "User Added Tenant";
              setProfile(tenant);
              setModalOpen(true);
            }}
          >
            <Icon name="user" />
            {tenant.first_name} {tenant.last_name}
            <Label.Detail>Custom</Label.Detail>
          </Label>
        );
      }
    });

  const pendingTenants = lease.lease_requests
    .filter((request) => request.accepted === null)
    .map(({ id, tenant_profile }) => (
      <Label
        as={"a"}
        className="pending profile"
        onClick={() => {
          tenant_profile.requestId = id;
          tenant_profile.pending = true;
          tenant_profile.type = "Registered Tenant";
          setProfile(tenant_profile);
          setModalOpen(true);
        }}
        key={tenant_profile.id}
      >
        <Icon name="wait" />
        {tenant_profile.first_name} {tenant_profile.last_name}
        <Label.Detail>Pending</Label.Detail>
      </Label>
    ));

  const landlordLabel = (
    <Label
      as={"a"}
      className="verified profile"
      onClick={() => {
        landlord.type = "Landlord";
        setProfile(landlord);
        setModalOpen(true);
      }}
    >
      <Icon name="user" />
      {landlord.first_name} {landlord.last_name}
    </Label>
  );

  const renderProfileModal = () => {
    const editButtons =
      isLandlord && profile.pending ? (
        <Button
          className="negative"
          onClick={() => handleDeleteRequest(profile.requestId)}
          icon="trash"
          label="Delete Pending Request"
          labelPosition="left"
        />
      ) : isLandlord && !profile.unique_number ? (
        <>
          <Button
            className="negative"
            floated="left"
            onClick={() => handleDeleteTenant(profile.id)}
            icon="trash"
            content="Delete Tenant"
            labelPosition="left"
          />
          <Button icon="edit" onClick={() => setIsEditing(true)} />
        </>
      ) : (
        <Button content="close" />
      );

    const saveCancelButtons = (
      <>
        <Button content="Cancel" onClick={() => setIsEditing(false)} />
        <Button
          content="Save"
          onClick={() => dispatch(submit("ProfileEditForm"))}
        />
      </>
    );

    return (
      <Modal
        large
        className="profile-view"
        open={isModalOpen}
        onClose={closeModal}
      >
        <Header>Profile</Header>
        {isEditing ? (
          <ProfileEditForm
            profileInfo={profile}
            handleProfileSubmit={handleProfileSubmit}
          />
        ) : (
          <ProfileView profileInfo={profile} />
        )}

        <Modal.Actions>
          {!isEditing && editButtons}
          {isEditing && saveCancelButtons}
        </Modal.Actions>
      </Modal>
    );
  };

  return (
    <>
      {renderProfileModal()}

      <Card.Content>
        <Table definition unstackable>
          {lease.termination_date && (
            <Table.Header fullWidth>
              <Table.Row>
                <Table.Cell textAlign="center" negative colSpan="2">
                  Terminated on{" "}
                  <Moment format="MMM DD, YYYY" date={lease.termination_date} />
                </Table.Cell>
              </Table.Row>
            </Table.Header>
          )}
          <Table.Body>
            <Table.Row color="red">
              <Table.Cell>Address</Table.Cell>
              <Table.Cell>
                {lease.address} - {lease.unit_number}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell width={2}>Period</Table.Cell>
              <Table.Cell>
                <Moment format="MMM DD, YYYY" date={lease.start_date} />
                <span> to </span>
                <Moment format="MMM DD, YYYY" date={lease.end_date} />
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Monthly Rent</Table.Cell>
              <Table.Cell>${lease.rent}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell colSpan="2" textAlign="center">
                Deposits
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Security </Table.Cell>
              <Table.Cell>${lease.security_deposit}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Last Month </Table.Cell>
              <Table.Cell>${lease.last_month_deposit}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell colSpan="2" textAlign="center">
                Landlord and Tenants
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Tenants</Table.Cell>
              <Table.Cell>
                {renderedTenants}
                {pendingTenants}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Landlord</Table.Cell>
              <Table.Cell>{landlordLabel}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Card.Content>
    </>
  );
};

export default LeaseCard;
