import {
  LOADING,
  LOADING_COMPLETE,
  REQUEST_SUCCESS,
  USER_SIGN_IN,
  USER_SIGN_OUT,
  USER_SIGN_UP,
  RESET_POP_UP,
  REQUEST_FAILED,
  SUCCESS_POPUP,
  FAILURE_POPUP,
} from "../actions/types";

const INITIAL_STATE = {
  popUpOpened: false,
  popUpMessage: null,
  requestFailed: false,
  backgroundColor: null,
  serverResponse: {},
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case LOADING_COMPLETE:
      return {
        ...state,
        isLoading: false,
      };
    case SUCCESS_POPUP:
      return {
        ...state,
        backgroundColor: "#00695c",
        popUpOpened: true,
        popUpMessage: action.payload,
      };
    case FAILURE_POPUP:
      return {
        ...state,
        backgroundColor: "#d32f2f",
        popUpOpened: true,
        popUpMessage: action.payload,
      };
    case REQUEST_SUCCESS:
      return {
        ...state,
      };
    case REQUEST_FAILED:
      return {
        ...state,
        // backgroundColor: "#d32f2f",
        requestFailed: true,
        // popUpOpened: true,
        // popUpMessage: action.payload.base,
        serverResponse: { ...action.payload },
      };
    case RESET_POP_UP:
      return {
        ...state,
        popUpOpened: false,
        popUpMessage: null,
      };
    case USER_SIGN_IN:
      return {
        ...state,
        popUpOpened: true,
        popUpMessage: "Welcome back!",
      };

    // case USER_SIGN_UP:
    //   return {
    //     ...state,
    //     isSignedIn: false,
    //     isLoading: false,
    //   };

    case USER_SIGN_OUT:
      return {
        ...state,
        popUpOpened: true,
        popUpMessage: "Signed out",
      };
    //
    // case EDIT_TENANT_PROFILE:
    //   return {
    //     ...state,
    //     loading: false,
    //     tenant_profile: action.payload,
    //   };
    //
    // case EDIT_LANDLORD_PROFILE:
    //   return {
    //     ...state,
    //     loading: false,
    //     landlord_profile: action.payload,
    //   };

    default:
      return state;
  }
};
