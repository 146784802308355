import React from "react";

import { Switch, Route } from "react-router-dom";

import { Container, Grid } from "semantic-ui-react";

import AuthRoute from "./Containers/AuthRoute";
import LandlordAuth from "./Containers/LandlordAuth";
import TenantAuth from "./Containers/TenantAuth";

import LandlordFeed from "./Containers/LandlordSide/LandlordLanding";
import CacheBuster from "./CacheBuster";

import SignInPage from "./Containers/Forms/UserSignIn";
import AccountSignUp from "./Containers/AccountSignUp";

import Home from "./Components/Home";
// import Account from './Containers/ProfileOverview';
import ProfileForm from "./Containers/Forms/ProfileForm";

//TENANT
import TenantView from "./Containers/TenantSide/Overview";

import PopUpMessage from "./Components/PopUpMessages";
import "./App.css";

class App extends React.Component {
  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
            <div style={{ paddingBottom: "10rem" }}>
              <PopUpMessage />
              <Switch>
                <TenantAuth path="/tenant/" component={TenantView} />

                <LandlordAuth path="/landlord" component={LandlordFeed} />

                <Route path="/signIn" component={SignInPage} />
                <Route path="/signUp" component={AccountSignUp} />
                <Route exact path="/" component={Home} />
              </Switch>
            </div>
          );
        }}
      </CacheBuster>
    );
  }
}

export default App;
