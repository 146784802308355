import React, { Component } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { signUp } from "../../actions";
import { Button, Form, Card, Header, Container } from "semantic-ui-react";

class TenantSignUp extends Component {
  renderError = ({ error, touched }) => {
    if (error && touched) {
      return {
        content: error,
        pointing: "above",
      };
    }
  };

  renderInput = ({ label, type, placeholder, input, meta }) => {
    return (
      <Form.Input
        {...input}
        {...input}
        type={type}
        error={this.renderError(meta)}
        placeholder={placeholder}
      />
    );
  };

  renderCheckBox = ({ label, type, placeholder, input }) => {
    return <Form.Checkbox {...input} label={label} />;
  };

  handleSubmit = (formData) => {
    formData.landlord_account = false;

    return this.props
      .signUp(formData)
      .then(() => this.props.history.push("/signIn"))
      .catch((err) => {
        throw new SubmissionError(err.data);
      });
  };

  render() {
    return (
      <div>
        <Card fluid>
          <Card.Content>
            <Header textAlign="center" content="Create a Tenant Account" />
          </Card.Content>
          <Card.Content>
            <Container>
              <Form>
                <Form.Group widths="equal">
                  <Field
                    name="first_name"
                    component={this.renderInput}
                    label="First Name"
                    type="text"
                    placeholder="First name"
                  />
                  <Field
                    name="last_name"
                    component={this.renderInput}
                    label="Last Name"
                    type="text"
                    placeholder="Last name"
                  />
                </Form.Group>
                <Field
                  name="email"
                  component={this.renderInput}
                  label="Email"
                  type="text"
                  placeholder="example@gmail.com"
                />
                <Field
                  name="password"
                  component={this.renderInput}
                  label="Password"
                  type="password"
                  placeholder="Password"
                />
                <Field
                  name="password_confirmation"
                  component={this.renderInput}
                  label="Confirm Password"
                  type="password"
                  placeholder="Confirm Password"
                />

                <Button
                  type="submit"
                  loading={this.props.loading}
                  fluid
                  disabled={this.props.invalid}
                  className="action-button"
                  onClick={this.props.handleSubmit(this.handleSubmit)}
                  content="Create Tenant Account"
                />
              </Form>
            </Container>
          </Card.Content>
          <Card.Content extra>
            Already have an account?
            <Link to="/signIn"> Log in</Link>
          </Card.Content>
        </Card>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  const lettersOnly = /^[A-Za-z]+$/;
  const emailRegEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (!formValues.first_name) {
    errors.first_name = "Required";
  }
  if (!formValues.last_name) {
    errors.last_name = "Required";
  }
  if (!lettersOnly.test(formValues.first_name)) {
    errors.first_name = "Wrong format";
  }
  if (!lettersOnly.test(formValues.last_name)) {
    errors.last_name = "Wrong format";
  }
  if (!formValues.email) {
    errors.email = "Email Required";
  }
  if (!emailRegEx.test(formValues.email)) {
    errors.email = "Email must be in the correct format";
  }
  if (!formValues.password) {
    errors.password = "Enter a password";
  } else if (formValues.password.length <= 4) {
    errors.password = "Passworld must be more than 4 characters";
  }

  if (formValues.password_confirmation !== formValues.password) {
    errors.password_confirmation = "This must match the password above";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    loading: state.app.isLoading,
  };
};

const wrappedForm = reduxForm({
  form: "TenantSignUp",
  validate,
})(TenantSignUp);

const connectedForm = connect(mapStateToProps, { signUp })(wrappedForm);

export default withRouter(connectedForm);
