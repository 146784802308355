import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getTenantActiveLease, getTenantLeases } from "../../actions";

import { Header, Message, Icon, Divider } from "semantic-ui-react";

import { Switch, Route, Link } from "react-router-dom";

import Loading from "../../Components/Loading";

import LeaseCard from "../../Components/LeaseCard";

const ActiveLease = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTenantActiveLease());
  }, [dispatch]);
  const activeLease = useSelector((state) => state.leases.lease);
  const loading = useSelector((state) => state.leases.isloading);

  const renderLoading = <Loading nameOfResource="Active Lease" />;

  const renderNoLeaseMessage = (
    <Message className="center-align" icon>
      <Icon name="cogs" />
      <Message.Content>
        <Message.Header>
          You currently don't have an Active Lease
        </Message.Header>
        Ask your Landlord to add you to a Lease
      </Message.Content>
    </Message>
  );

  const renderActiveLease = Object.keys(activeLease).length ? (
    <LeaseCard lease={activeLease} />
  ) : (
    renderNoLeaseMessage
  );

  return (
    <>
      <Link to={`/tenant/leases`} style={{ float: "right" }}>
        View All Leases
      </Link>
      <Header>CURRENT LEASE</Header>
      {loading ? renderLoading : renderActiveLease}
    </>
  );
};

export default ActiveLease;
