import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getTenantLeases } from "../../actions";

import { Message, Header } from "semantic-ui-react";

import PastLeasesTable from "../../Components/unitDetails/PastLeases";

const PastLeases = () => {
  const leases = useSelector((state) => state.leases.userLeases);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTenantLeases());
  }, [dispatch]);

  const renderNoHistoryMessage = <Message header="No Lease history" />;

  const renderPastLeases = <PastLeasesTable leases={leases} />;

  return (
    <>
      <Header>Lease History</Header>
      {Object.keys(leases).length ? renderNoHistoryMessage : renderPastLeases}
    </>
  );
};

export default PastLeases;
