import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import {
  completeMaintenanceRequest,
  getAllActiveMaintenanceRequests,
} from "../../actions";

import { Header, Segment, Message } from "semantic-ui-react";

import MaintenanceCard from "../../Components/MaintenanceRequestCard";
import Loading from "../../Components/Loading";

function Feed() {
  const dispatch = useDispatch();
  // const activeLeases = useSelector((state) => state.leases.activeLeases);

  const completeAction = (request) =>
    dispatch(completeMaintenanceRequest(request));

  useEffect(() => {
    dispatch(getAllActiveMaintenanceRequests());
  }, [dispatch]);

  const maintenanceRequests = useSelector((state) => state.maintenanceRequests);

  const isLoading = useSelector((state) => state.maintenanceRequests.isLoading);

  const loading = <Loading nameOfResource="Maintenance Requests" />;

  const noRequests = (
    <Message
      icon="thumbs up"
      header="Congrats!"
      content="Nothing requires your attention right now. Sit back and relax."
    />
  );

  const renderedRequests =
    maintenanceRequests.allActiveRequests &&
    Object.keys(maintenanceRequests.allActiveRequests).length
      ? Object.values(maintenanceRequests.allActiveRequests).map(
          ({ property, unitId, requests }) => {
            return (
              <div key={property}>
                <Header
                  as={Link}
                  to={`/landlord/units/${unitId}/active-lease`}
                  content={property}
                  style={{ marginTop: "1rem" }}
                />
                {requests.map((item) => (
                  <MaintenanceCard
                    key={item.id}
                    tix={item}
                    completeAction={completeAction}
                  />
                ))}
              </div>
            );
          }
        )
      : noRequests;

  return (
    <>
      <Header>Your Feed:</Header>
      {isLoading ? loading : renderedRequests}
    </>
  );
}

export default Feed;
