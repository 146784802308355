import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

function AddressField(props) {
  const onInputChange = (inputValue, { action }) => {
    console.log("ON INPUT CHNAGE", inputValue);
    console.log("O INPUT CHNAGE ACTION", action);
    switch (action) {
      case "select-option":
        props.onChange(inputValue.label);
        return;
      case "input-change":
        props.onChange(inputValue.label);
        return;
      case "clear":
        props.onChange(inputValue);
        return;
      default:
    }
  };

  return (
    <GooglePlacesAutocomplete
      apiKey="AIzaSyC9hlguszVX2bv6Zxyv2JKDSTh15Phgu4A"
      minLengthAutocomplete={5}
      selectProps={{
        placeholder: props.placeholder,
        onChange: onInputChange,
        label: props.label,
        isClearable: true,
        defaultInputValue: props.value,
      }}
    />
  );
}

export default AddressField;
