import React, { useState } from "react";
import { Modal, Icon, Button, Header } from "semantic-ui-react";

import LeaseCard from "../LeaseCard";

const LeaseDetailModal = (props) => {
  const { closeModal, lease, open, makeActive } = props;
  console.log("LEASE", lease);

  const renderModalContent = Object.keys(lease).length ? (
    <>
      <Modal.Header>
        <Header>
          <Header.Content>
            <Header.Subheader>Lease Details</Header.Subheader>
            {lease.address}
          </Header.Content>
        </Header>
      </Modal.Header>

      <LeaseCard lease={lease} />

      <Modal.Actions>
        <Header.Subheader
          style={{ float: "left" }}
          content={`ID# ${lease.lease_num}`}
        />
      </Modal.Actions>
    </>
  ) : (
    <p>LOADING</p>
  );
  return (
    <Modal open={open} onClose={() => closeModal()}>
      {renderModalContent}
    </Modal>
  );
};

export default LeaseDetailModal;
