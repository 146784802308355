import React from "react";
import { Dropdown, Button } from "semantic-ui-react";

//Takes an options array of objects for each action to be displayed
// Example:
// const actionMenuOptions = [
//     { icon: 'edit', text: 'Edit Lease', action: () =>
//     { icon: 'delete', text: 'Terminate', action: () =>
//     { icon: 'user plus', text: 'Add Tenant', action: () =>
//     ]

const MoreOptions = ({ options }) => (
  <Dropdown as={Button} text="Options" style={{ float: "right" }}>
    <Dropdown.Menu>
      {options.map((item) => (
        <Dropdown.Item
          key={item.text}
          text={item.text}
          description={item.description}
          icon={item.icon}
          onClick={item.action}
          style={item.style}
        />
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

export default MoreOptions;
