import {
  FETCH_ACTIVE_MAINTENANCE_REQUESTS,
  FETCH_ALL_MAINTENACE_CURRENT_LEASE,
  CLOSE_MAINTENANCE,
  CREATE_MAINTENANCE_REQ,
  LOADING_MAINTENANCE_REQUEST,
  LOADING_MAINTENANCE_REQUEST_COMPLETE,
  CLEAR_MAINTENANCE_REQESTS,
  USER_SIGN_OUT,
} from "../actions/types";
import _ from "lodash";

const INITIAL_STATE = {
  isLoading: false,
  activeRequestsForCurrentLease: null,
  completedRequestsForCurrentLease: null,
  allActiveRequests: null,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_MAINTENANCE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_MAINTENANCE_REQUEST_COMPLETE:
      return {
        ...state,
        isLoading: false,
      };

    case CLEAR_MAINTENANCE_REQESTS:
      return {
        ...INITIAL_STATE,
      };
    case FETCH_ACTIVE_MAINTENANCE_REQUESTS:
      //Refactor this method later (maybe use sort method)
      const addressArray = action.payload.map((item) => {
        return {
          fullAddress: `${item.lease.address} ${item.lease.unit_number}`,
          unitId: item.lease.unit_id,
        };
      });

      console.log("ADDRESS ARR", addressArray);

      //Keeps only unique values from address array
      const uniqueAddressArray = [...new Set(addressArray)];

      const sortedArray = uniqueAddressArray.map((address) => {
        const request = action.payload.filter(
          (request) => request.lease.unit_id === address.unitId
        );
        return {
          property: address.fullAddress,
          unitId: address.unitId,
          requests: request,
        };
      });

      console.log("SORTED ARR", sortedArray);
      return {
        ...state,
        allActiveRequests: { ..._.mapKeys(sortedArray, "property") },
      };
    case FETCH_ALL_MAINTENACE_CURRENT_LEASE:
      return {
        ...state,
        activeRequestsForCurrentLease: {
          ..._.mapKeys(action.payload.active, "id"),
        },
        completedRequestsForCurrentLease: {
          ..._.mapKeys(action.payload.completed, "id"),
        },
      };

    case CREATE_MAINTENANCE_REQ:
      return {
        ...state,
        activeRequestsForCurrentLease: {
          ...state.activeRequestsForCurrentLease,
          [action.payload.id]: action.payload,
        },
      };

    case CLOSE_MAINTENANCE:
      const currentRequests = Object.values(state.allActiveRequests);

      const newMaintenanceArray = currentRequests
        .map((property) => {
          const newRequestArray = property.requests.filter(
            (request) => request.id !== action.payload.id
          );
          return { ...property, requests: newRequestArray };
        })
        .filter((property) => property.requests.length !== 0);

      const {
        [action.payload.id]: removedRequest,
        ...restOfRequest
      } = state.activeRequestsForCurrentLease;

      return {
        ...state,
        activeRequestsForCurrentLease: {
          ...restOfRequest,
        },
        completedRequestsForCurrentLease: {
          ...state.completedRequestsForCurrentLease,
          [action.payload.id]: action.payload,
        },
        allActiveRequests: { ..._.mapKeys(newMaintenanceArray, "property") },
      };

    case USER_SIGN_OUT:
      return {
        ...state,
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};
