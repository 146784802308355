import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { submit, SubmissionError } from "redux-form";
import { Link } from "react-router-dom";

import {
  fetchAllLeaseRequests,
  respondToLeaseRequest,
  editTenantProfile,
  signOut,
} from "../../actions";

import { Header, Icon, Button, Card } from "semantic-ui-react";

import ProfileView from "../../Components/ProfileView";
import ProfileEditForm from "../Forms/ProfileEditForm";
import PastLeases from "./PastLeases";

const Account = () => {
  const { email, tenant_profile } = useSelector((state) => state.auth);

  const form = useSelector((state) => state.form);

  const [edit, setEdit] = useState(false);

  const dispatch = useDispatch();

  const handleUserSignOut = () => dispatch(signOut());

  const handleProfileSubmit = (profile) =>
    dispatch(editTenantProfile(profile))
      .then(() => toggleEdit())
      .catch((error) => {
        throw new SubmissionError(error);
      });

  const toggleEdit = () => setEdit(!edit);

  const editButton = (
    <Link onClick={() => toggleEdit()}>
      <Icon name="edit" />
      Edit
    </Link>
  );

  const saveOrCancelButtons = (
    <>
      <Button
        content="Save"
        compact
        disabled={
          form["ProfileEditForm"] ? form["ProfileEditForm"].syncErrors : true
        }
        onClick={() => dispatch(submit("ProfileEditForm"))}
      />
      <Button
        content="Cancel"
        compact
        onClick={() => {
          toggleEdit();
        }}
      />
    </>
  );

  return (
    <>
      <div className="center-container">
        <Header content="Tenant Account" className="no-margins" />
        <span>{edit ? saveOrCancelButtons : editButton}</span>
      </div>

      <Card fluid>
        {edit ? (
          <ProfileEditForm
            profileInfo={tenant_profile}
            handleProfileSubmit={handleProfileSubmit}
          />
        ) : (
          <ProfileView profileInfo={tenant_profile} email={email} />
        )}
      </Card>

      <Button
        floated="right"
        onClick={() => handleUserSignOut()}
        content="Sign out"
      />
    </>
  );
};

export default Account;
