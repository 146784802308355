import { api } from "./api";

import {
  getPendingLeaseRequests,
  respondToLeaseRequest,
  getTenantActiveLease,
  getTenantLeases,
  getTenantLeaseMaintenanceRequests,
  editTenantProfile,
} from "./tenantActions";

import {
  removeUnit,
  clearUnit,
  createNewUnit,
  clearMaintenanceRequests,
  createNewMaintenance,
  completeMaintenanceRequest,
  filterMaintenanceRequests,
  sortActiveLeaseMaintenance,
  selectMaintenanceRequests,
  getUnitNotes,
  updatePropertyName,
  selectProperty,
  getUnitLeases,
  editUnit,
  getUnitDetailsAndActiveLease,
  getLeaseDetails,
  clearActiveLease,
  createNewLease,
  getAllActiveMaintenanceRequests,
  getSelectedProperty,
  createNewProperty,
  createNewNote,
  terminateLease,
  makeLeaseActive,
  addTenantToLease,
  getAllUserProperties,
  getUnitDetails,
  getUserUnits,
  removeProperty,
  getLeaseNotes,
  removeLeaseNote,
  removeUnitNote,
  removePropertyNote,
  getAllTenants,
  getTenant,
  editCustomTenantProfile,
  removeCustomTenant,
  revokeLeaseRequest,
} from "./landlordActions";

import { userSignIn, userSignOut, userSignUp } from "./api";

import {
  EDIT_TENANT_PROFILE,
  EDIT_LANDLORD_PROFILE,
  USER_SIGN_IN,
  USER_SIGN_UP,
  USER_SIGN_OUT,
  LOADING,
  LOADING_COMPLETE,
  RESET_POP_UP,
  SUCCESS_POPUP,
  FAILURE_POPUP,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
} from "./types";

export const successPopUp = (popUpMessage) => (dispatch) => {
  dispatch({
    type: SUCCESS_POPUP,
    payload: popUpMessage,
  });
};

export const failurePopUp = (error) => (dispatch) => {
  console.log("ERROR", error);
  dispatch({
    type: FAILURE_POPUP,
    payload: error,
  });
};

export const requestSuccess = (type, payload) => (dispatch) => {
  dispatch({
    type,
    payload,
  });

  return dispatch({ type: REQUEST_SUCCESS });
};

//type is the resource type the failure is for
export const requestFailed = (error, type) => (dispatch) => {
  if (type) {
    dispatch({ type: type });
  }
  console.log("REQUEST FAILED", error);
  if (error.response) {
    // client received an error response (5xx, 4xx)
    console.log("RESPONSE ERROR", error.response);
    dispatch({ type: REQUEST_FAILED, payload: error.response.data });
    throw error.response;
  } else if (error.request) {
    // client never received a response, or request never left
    console.log("REQUEST ERROR", error.request);

    dispatch({ type: REQUEST_FAILED, payload: error.request });

    dispatch(failurePopUp("Server Error"));

    throw error.request;
  } else {
    console.log("FAILED");
    dispatch({ type: REQUEST_FAILED, payload: { data: "Server Error" } });
    dispatch(failurePopUp("Error"));
  }
};

export const resetPopUp = () => (dispatch) => {
  dispatch({
    type: RESET_POP_UP,
  });
};

//PROFILE ACTIONS
export const editProfile = (profileData, options) => async (
  dispatch,
  getState
) => {
  const token = getState().auth.token;

  if (options.type === "tenant") {
    const tenantResponse = await api.patch(
      `/tenant_profiles/${options.id}`,
      { tenant_profile: profileData },
      {
        headers: {
          Authorization: `Token token=${token}`,
        },
      }
    );

    dispatch({
      type: EDIT_TENANT_PROFILE,
      payload: tenantResponse.data.tenant_profile,
    });
  }

  if (options.type === "landlord") {
    const landlordResponse = await api.patch(
      `/landlord_profiles/${options.id}`,
      { landlord_profile: profileData },
      {
        headers: {
          Authorization: `Token token=${token}`,
        },
      }
    );

    dispatch({
      type: EDIT_LANDLORD_PROFILE,
      payload: landlordResponse.data.landlord_profile,
    });
  }
};

//USER ACTIONS
export const signIn = (credentials) => (dispatch) => {
  return dispatch(userSignIn(credentials))
    .then((response) => {
      dispatch(requestSuccess(USER_SIGN_IN, response.data));
      return response;
    })
    .then(() => dispatch({ type: LOADING_COMPLETE }))
    .then(() => dispatch(successPopUp("Welcome back!")))
    .catch((error) => {
      dispatch({ type: LOADING_COMPLETE });
      dispatch(failurePopUp("Invalid login"));
      dispatch(requestFailed(error));
    });
};

export const signOut = () => (dispatch) => {
  dispatch(requestSuccess(USER_SIGN_OUT));
  dispatch({ type: LOADING_COMPLETE });
  dispatch(successPopUp("Signed out"));
};

export const signUp = (credentials) => (dispatch) => {
  return dispatch(userSignUp(credentials))
    .then((response) =>
      dispatch(requestSuccess(USER_SIGN_UP, response.data.user))
    )
    .then(() => dispatch({ type: LOADING_COMPLETE }))
    .then(() => dispatch(successPopUp("Sign up success!")))
    .catch((error) => dispatch(requestFailed(error, LOADING_COMPLETE)));
};

export {
  makeLeaseActive,
  respondToLeaseRequest,
  clearActiveLease,
  //Landlord actions,
  removeUnit,
  clearUnit,
  createNewUnit,
  clearMaintenanceRequests,
  createNewMaintenance,
  completeMaintenanceRequest,
  filterMaintenanceRequests,
  sortActiveLeaseMaintenance,
  selectMaintenanceRequests,
  getUnitNotes,
  getUnitLeases,
  selectProperty,
  updatePropertyName,
  editUnit,
  getUnitDetailsAndActiveLease,
  getLeaseDetails,
  createNewLease,
  getAllActiveMaintenanceRequests,
  getSelectedProperty,
  createNewProperty,
  createNewNote,
  terminateLease,
  addTenantToLease,
  getAllUserProperties,
  getUnitDetails,
  getUserUnits,
  removeProperty,
  getLeaseNotes,
  removeLeaseNote,
  removeUnitNote,
  removePropertyNote,
  getAllTenants,
  getTenant,
  //TENANT
  getTenantActiveLease,
  getTenantLeases,
  getTenantLeaseMaintenanceRequests,
  getPendingLeaseRequests,
  editTenantProfile,
  editCustomTenantProfile,
  removeCustomTenant,
  revokeLeaseRequest,
};
