import React, { Component } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { signIn } from "../../actions";
import { Button, Form, Card, Header, Container } from "semantic-ui-react";

class SignIn extends Component {
  renderError = ({ error, touched }) => {
    if (error && touched) {
      return {
        content: error,
        pointing: "above",
      };
    }
  };
  renderInput = ({ label, type, placeholder, input, meta }) => {
    return (
      <Form.Input
        {...input}
        label={label}
        type={type}
        placeholder={placeholder}
        error={this.renderError(meta)}
      />
    );
  };

  handleSubmit = (formData) => {
    // const { pathname } = this.props.location.state.from;
    return this.props
      .signIn(formData)
      .then(() => {
        const {
          landlord_profile,
          tenant_profile,
          isLandlord,
        } = this.props.auth;

        if (isLandlord) {
          return this.props.history.push("/landlord/feed");
        } else {
          return this.props.history.push("/tenant/active-lease");
        }
      })

      .catch((error) => {
        throw new SubmissionError({
          password: "Incorrect email or password",
        });
      });
  };

  render() {
    return (
      <>
        <div
          style={{
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Header as="h1" content="RENZY" />
          <Header.Subheader content="The easiest way to track and manage rentals" />
          <Card fluid>
            <Card.Content>
              <Header textAlign="center" content="Sign In" />
            </Card.Content>
            <Card.Content>
              <Container>
                <Form>
                  <Field
                    name="email"
                    component={this.renderInput}
                    label="Email"
                    type="text"
                    placeholder="example@gmail.com"
                  />
                  <Field
                    name="password"
                    component={this.renderInput}
                    label="Password"
                    type="password"
                    placeholder="my secret password"
                  />
                  <Button
                    type="submit"
                    disabled={this.props.invalid}
                    loading={this.props.loading}
                    circular
                    fluid
                    className="action-button"
                    onClick={this.props.handleSubmit(this.handleSubmit)}
                  >
                    Submit
                  </Button>
                </Form>
              </Container>
            </Card.Content>
            <Card.Content extra>
              Don't have an account?
              <Link to="/signUp"> Create an account</Link>
            </Card.Content>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.app.isLoading,
    auth: state.auth,
  };
};

const validate = (formValues) => {
  const errors = {};

  const emailRegEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (!formValues.email) {
    errors.email = "Enter your email";
  }
  if (!emailRegEx.test(formValues.email)) {
    errors.email = "Email must be in the correct format";
  }
  if (!formValues.password) {
    errors.password = "Enter your password";
  }

  return errors;
};

const wrappedForm = reduxForm({
  form: "signIn",
  validate,
})(SignIn);

const connectedForm = connect(mapStateToProps, { signIn })(wrappedForm);

export default withRouter(connectedForm);
