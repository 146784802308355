import React from "react";
import NoteCard from "../NoteCard";

import { Message, Card, Button, List } from "semantic-ui-react";

const PropertyNotes = ({ notes }) => {
  const renderNotes = (
    <Card fluid>
      <Card.Content>
        <List divided relaxed>
          {Object.values(notes).map((note) => (
            <NoteCard note={note} />
          ))}
        </List>
      </Card.Content>
    </Card>
  );

  const noNotesMessage = (
    <Message>
      <Message.Header>There are no notes to display</Message.Header>
    </Message>
  );

  return (
    <>{Object.values(notes).length !== 0 ? renderNotes : noNotesMessage}</>
  );
};

export default PropertyNotes;
