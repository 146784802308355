import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, submit } from "redux-form";
import { Link, useHistory } from "react-router-dom";

import { updatePropertyName, removeProperty } from "../../../actions";

import { Icon, Header, Menu, Table, Button } from "semantic-ui-react";

import PropertyNameField from "../../Forms/Fields/PropertyNameInput";
// import SettingsTab from "../../../Components/SettingsTab";

const Settings = (props) => {
  const [state, setState] = useState({ edit: "" });
  const history = useHistory();
  const dispatch = useDispatch();

  const propertyDetails = useSelector(
    (state) => state.properties.selectedProperty
  );

  const editPropertyAction = (form) => {
    dispatch(updatePropertyName(form));
    setState({ edit: "" });
  };

  const handleDeleteProperty = () => {
    return dispatch(removeProperty(propertyDetails.id)).then(() =>
      history.push("/landlord/properties")
    );
  };

  const renderEditButtons = (
    <>
      <Button
        content="Save"
        onClick={() => dispatch(submit("PropertyNameField"))}
      />
      <Button content="Cancel" onClick={() => setState({ edit: "" })} />
    </>
  );

  const { pathname } = props.location;
  const { url, params } = props.match;
  const { address, name } = propertyDetails;

  return (
    <>
      <Table unstackable definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell width="3">Name</Table.Cell>
            <Table.Cell>
              {state.edit === "Name" ? (
                <PropertyNameField
                  propertyDetails={propertyDetails}
                  editPropertyAction={editPropertyAction}
                />
              ) : (
                name
              )}
            </Table.Cell>
            <Table.Cell collapsing>
              {state.edit === "Name" ? (
                renderEditButtons
              ) : (
                <a onClick={() => setState({ edit: "Name" })}>
                  <Icon name="edit" />
                  Edit
                </a>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Address</Table.Cell>
            <Table.Cell>
              {state.edit === "Address" ? (
                <input placeholder="Address" />
              ) : (
                address
              )}
            </Table.Cell>
            <Table.Cell collapsing></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Button
        fluid
        negative
        icon="trash"
        content="Delete Property"
        onClick={() => handleDeleteProperty()}
      />
    </>
  );
};

export default Settings;
