import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  completeMaintenanceRequest,
  selectMaintenanceRequests,
  clearMaintenanceRequests,
} from "../../../actions";

import { Switch, Route, Link } from "react-router-dom";

import { Message } from "semantic-ui-react";

import MaintenanceRequestView from "../../../Components/unitDetails/MaintenanceRequests";
import NewMaintenance from "../../../Components/Modals/NewMaintenance";

import Loading from "../../../Components/Loading";

const MaintenanceTicketsTab = () => {
  const dispatch = useDispatch();

  const maintenanceTickets = useSelector((state) => state.maintenanceRequests);

  const lease = useSelector((state) => state.leases.lease);

  const loading = useSelector((state) => state.maintenanceRequests.isLoading);

  const closeMaintenanceAction = (request) =>
    dispatch(completeMaintenanceRequest(request));

  useEffect(() => {
    if (Object.keys(lease).length) {
      dispatch(selectMaintenanceRequests(lease.id));
    }
    return () => dispatch(clearMaintenanceRequests());
  }, [dispatch]);

  const renderMaintenanceTix = maintenanceTickets.activeRequestsForCurrentLease ? (
    <>
      <NewMaintenance leaseId={lease.id} />
      <MaintenanceRequestView
        tickets={maintenanceTickets}
        handleCloseMaintenanceAction={closeMaintenanceAction}
      />
    </>
  ) : (
    <Message
      icon="ban"
      content="Create a Lease to begin tracking maintenance requests"
    />
  );

  return loading ? (
    <Loading nameOfResource="Maintenance Requests" message="Please wait..." />
  ) : (
    renderMaintenanceTix
  );
};

export default MaintenanceTicketsTab;

//TO DO:

//1. implement fetch all leases in detils tabs
//2. implement new structure for viewing leases on tenant side
//3. refactor properties rsources to refelect new structure
