import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getUnitLeases } from "../../../actions";

import { Switch, Route, Link } from "react-router-dom";

import PastLeasesView from "../../../Components/unitDetails/PastLeases";

const PastLeases = ({ match }) => {
  // const [sortedLeases, setSortedLeases] = useState([]);
  const { id } = match.params;
  const leases = useSelector((state) => state.leases.unitLeases);
  const dispatch = useDispatch();

  //CONSIDER FETCHING PAST LEASE ONLY AFTER USER EXPANDS PAST LEASE SECTION
  useEffect(() => {
    dispatch(getUnitLeases(id));
  }, [dispatch, id]);

  const sortedLeases = Object.values(leases)
    .filter((lease) => !lease.active)
    .sort((current, next) => {
      return current.termination_date > next.termination_date
        ? -1
        : current.termination_date < next.termination_date
        ? 1
        : 0;
    });

  // useEffect(() => {
  //   const allLeases = unit.leases
  //     .filter((lease) => !lease.active)
  //     .sort((current, next) => {
  //       return current.termination_date > next.termination_date
  //         ? -1
  //         : current.termination_date < next.termination_date
  //         ? 1
  //         : 0;
  //     });
  //
  //   return setSortedLeases(allLeases);
  // }, [unit.leases]);

  const makeLeaseActiveAction = (activeLease) =>
    console.log("MAKE LEASE ACTIVE");
  // // dispatch(makeLeaseActive(activeLease));

  const renderPastLeases = (
    <PastLeasesView
      leases={sortedLeases}
      handleCreateActiveLease={makeLeaseActiveAction}
    />
  );

  return <>{renderPastLeases}</>;
};

export default PastLeases;
