import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLeaseDetails } from "../../actions";
import { Card, Header, Icon, Button, Label, Table } from "semantic-ui-react";
import Moment from "react-moment";

// import LeaseCard from "../LeaseCard";
import LeaseDetailModal from "../Modals/LeaseDetailView";

const PastLeases = ({ leases, handleCreateActiveLease }) => {
  const dispatch = useDispatch();
  const { lease } = useSelector((state) => state.leases);

  const [state, setState] = useState({});

  const [selectedLease, setSelectedLease] = useState({
    lease: { property: {}, unit: {} },
    open: false,
  });

  const { activeIndex } = state;

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    // const { activeIndex } = state
    const newIndex = activeIndex === index ? -1 : index;

    setState({ activeIndex: newIndex });
  };

  const handleActiveClick = (lease) => handleCreateActiveLease(lease);

  const handleSelectLease = (lease) => {
    dispatch(getLeaseDetails(lease.id));

    setSelectedLease({ open: true });
  };

  const closeModal = () => setSelectedLease({ ...selectedLease, open: false });

  const renderRows = Object.values(leases).map((lease) => {
    return (
      <Table.Row key={lease.id} onClick={() => handleSelectLease(lease)}>
        <Table.Cell>{lease.lease_num}</Table.Cell>
        <Table.Cell>
          <Moment format="MMM DD, YYYY" date={lease.start_date} />
        </Table.Cell>
        <Table.Cell>
          <Moment format="MMM DD, YYYY" date={lease.end_date} />
        </Table.Cell>
        <Table.Cell>${lease.rent}</Table.Cell>
        <Table.Cell>
          {lease.termination_date && (
            <Moment format="MMM DD, YYYY" date={lease.termination_date} />
          )}
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <>
      <Card fluid>
        <Table celled selectable unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Start</Table.HeaderCell>
              <Table.HeaderCell>End</Table.HeaderCell>
              <Table.HeaderCell>Rent</Table.HeaderCell>
              <Table.HeaderCell>Termed</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{renderRows}</Table.Body>
        </Table>
      </Card>
      <LeaseDetailModal
        lease={lease}
        closeModal={closeModal}
        open={selectedLease.open}
      />
    </>
  );
};

export default PastLeases;
