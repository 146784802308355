import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Segment, Header, Icon, Button } from 'semantic-ui-react';


function NoActiveLease(){

  const match = useRouteMatch()
  
  return (
    <Segment placeholder>
      <Header icon>
        <Icon name='cogs ui' />
        <Header.Content as='h2'>VACANT</Header.Content>
        This unit is current vacant.
      </Header>
      <Button
        as={Link}
        to={`${match.url}/new-lease-form`}
        content="New Lease"
        icon="plus"/>
    </Segment>
  )
}

export default NoActiveLease;
