import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { deleteUnit } from "../../../actions";

import { Icon, Header, Menu } from "semantic-ui-react";

import NewNoteModal from "../../../Components/Modals/NewNotes";
import PropertyNotes from "../../../Components/propertyDetails/PropertyNotes";

const NotesTab = (props) => {
  const dispatch = useDispatch();
  const notes = useSelector((state) => state.properties.selectedProperty.notes);

  const { pathname } = props.location;
  const { url, params } = props.match;

  return (
    <>
      <NewNoteModal
        headerTitle="Property Notes"
        noteParentType="property_id"
        noteParentId={params.id}
      />
      <PropertyNotes notes={notes} />
    </>
  );
};

export default NotesTab;
