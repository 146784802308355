import {
  USER_SIGN_IN,
  USER_SIGN_OUT,
  USER_SIGN_UP,
  EDIT_LANDLORD_PROFILE,
  EDIT_TENANT_PROFILE,
} from "../actions/types";

const INITIAL_STATE = {
  email: null,
  id: null,
  token: null,
  isSignedIn: false,
  isLandlord: false,
  landlord_profile: {},
  tenant_profile: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_SIGN_IN:
      return {
        ...state,
        email: action.payload.user.email,
        id: action.payload.user.id,
        token: action.payload.token,
        landlord_profile: { ...action.payload.user.landlord_profile },
        tenant_profile: { ...action.payload.user.tenant_profile },
        isSignedIn: true,
        isLandlord: action.payload.user.landlord_account,
      };

    case USER_SIGN_UP:
      return {
        ...state,
        isSignedIn: false,
      };

    case USER_SIGN_OUT:
      return {
        ...state,
        ...INITIAL_STATE,
      };

    case EDIT_TENANT_PROFILE:
      return {
        ...state,
        tenant_profile: action.payload,
      };

    // case EDIT_LANDLORD_PROFILE:
    //   return {
    //     ...state,
    //     landlord_profile: action.payload,
    //   };

    default:
      return state;
  }
};
