import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getSelectedProperty, selectProperty } from "../../../actions";

import { Link, Route, Switch } from "react-router-dom";
import { Icon, Header, Menu } from "semantic-ui-react";

// import SettingsTab from "../../../Components/SettingsTab";
import Settings from "./Settings";

import UnitsTab from "./UnitsTab";
import NotesTab from "./NotesTab";

const PropertyOverview = (props) => {
  const dispatch = useDispatch();

  const allProperties = useSelector((state) => state.properties.userProperties);
  const { address, name } = useSelector(
    (state) => state.properties.selectedProperty
  );

  const { pathname } = props.location;
  const { url, params } = props.match;

  const [state, setState] = useState({});

  useEffect(() => {
    setState({ activeItem: pathname });
  }, [pathname]);

  useEffect(() => {
    //select property from already loaded property
    if (Object.keys(allProperties).length) {
      dispatch(selectProperty(params.property_id));
    } else {
      //if there are no loaded properties already, then fetch property from backend
      dispatch(getSelectedProperty(params.property_id));
    }
  }, [params.id, dispatch]);

  const { activeItem } = state;

  //SORT UNITS IN THE REDUCER BEFORE DISPLAYING
  return (
    <div className="column centered">
      <Header as="h4" dividing>
        <Link to={`/landlord/properties`}>
          <Icon name="arrow left" />
          <Header.Content>All Properties</Header.Content>
        </Link>
      </Header>

      <Header as="h3">
        <Header.Subheader content="Property Details" />
        <Header.Content content={name ? name : address} />
        {name && <Header.Subheader content={address} />}
      </Header>

      <Menu pointing secondary>
        <Menu.Item
          name="Units"
          active={activeItem === `${url}/units`}
          as={Link}
          to={`${url}/units`}
        />
        <Menu.Item
          name="Notes"
          active={activeItem === `${url}/notes`}
          as={Link}
          to={`${url}/notes`}
        />
        <Menu.Item
          name="Settings"
          position="right"
          active={activeItem === `${url}/settings`}
          as={Link}
          to={`${url}/settings`}
        />
      </Menu>

      <Switch>
        <Route
          path={`/landlord/properties/:property_id/notes`}
          component={NotesTab}
        />
        <Route
          path={`/landlord/properties/:property_id/settings`}
          component={Settings}
        />

        <Route
          path={`/landlord/properties/:property_id`}
          component={UnitsTab}
        />
      </Switch>
    </div>
  );
};

export default PropertyOverview;
