import {
  FETCH_ALL_TENANTS,
  FETCH_TENANT,
  USER_SIGN_OUT,
} from "../actions/types";
import _ from "lodash";

const INITIAL_STATE = {
  tenants: {},
  currentLeaseTenant: {},
  tenant: {},
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_TENANTS:
      return {
        ...state,
        tenants: { ..._.mapKeys(action.payload, "id") },
      };

    case FETCH_TENANT:
      return {
        ...state,
        tenant: action.payload,
      };

    case USER_SIGN_OUT:
      return {
        ...state,
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};
