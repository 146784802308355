import React, { Component } from "react";
import { reduxForm, Field, destroy } from "redux-form";
import { connect } from "react-redux";

import { editProfile } from "../../actions";

import { Form, Button, Header } from "semantic-ui-react";
import { colors } from "../../Styles";

import FormConfirmation from "./FormConfirmation";

//No hooks for redux-forms; must use HOC

class ProfileForm extends Component {
  state = { page: 1, accountType: null };

  componentWillMount() {
    const { user } = this.props;
    if (user.landlord_profile) {
      this.setState({ accountType: "landlord" });
    }
    if (user.tenant_profile) {
      this.setState({ accountType: "tenant" });
    }
  }

  renderError = ({ error, touched }) => {
    if (error && touched) {
      return {
        content: error,
        pointing: "above",
      };
    }
  };

  renderInput = ({ placeholder, label, type, input, meta }) => {
    return (
      <Form.Input
        {...input}
        error={this.renderError(meta)}
        label={label}
        placeholder={placeholder}
        value={input.value}
      />
    );
  };

  nextPage = () => this.setState({ page: this.state.page + 1 });

  previousPage = () => this.setState({ page: this.state.page - 1 });

  editProfileData = async (formData) => {
    let options = {};
    const { user } = this.props;

    if (this.state.accountType === "landlord") {
      options = { type: "landlord", id: user.landlord_profile.id };
    }
    if (this.state.accountType === "tenant") {
      options = { type: "tenant", id: user.tenant_profile.id };
    }
    await this.props
      .editProfile(formData, options)
      .then(() => this.props.dispatch(destroy("ProfileForm")));
  };

  render() {
    const { page } = this.state;

    return (
      <>
        <Header>Edit Profile</Header>
        <Form onSubmit={this.props.handleSubmit(this.editProfileData)}>
          {page === 1 && (
            <>
              <Form.Group widths="equal">
                <Field
                  name="first_name"
                  component={this.renderInput}
                  placeholder="John"
                  label="First Name"
                  type="text"
                />
                <Field
                  name="last_name"
                  component={this.renderInput}
                  placeholder="Smith"
                  label="Last Name"
                  type="text"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Field
                  name="phone"
                  component={this.renderInput}
                  placeholder="(978)232-0099"
                  label="Phone Number"
                  type="number"
                />
                <Field
                  name="user_name"
                  component={this.renderInput}
                  placeholder="Dragon Slayer243"
                  label="Username"
                  type="text"
                />
              </Form.Group>
              <Button
                style={colors.inactive}
                disabled={this.props.invalid ? true : false}
                onClick={() => this.nextPage()}
                content="Next"
                icon="right arrow"
                labelPosition="right"
              />
            </>
          )}

          {page === 2 && (
            <FormConfirmation
              previousPage={this.previousPage}
              formName="ProfileForm"
              submitButtonName="Save Profile"
              formHeader="Profile Overview"
              formObj={this.props.formObj}
              loading={this.props.loading}
            />
          )}
        </Form>
      </>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.first_name) {
    errors.first_name = "Please enter your first name";
  }
  if (!formValues.last_name) {
    errors.last_name = "Please enter your last name";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    formObj: state.form.ProfileForm,
    loading: state.properties.loading,
    user: state.auth,
  };
};

// const submitSuccessCallback = (result, dispatch) => {
//   return dispatch(destroy('ProfileForm'));
// }

const wrappedForm = reduxForm({
  form: "ProfileForm",
  destroyOnUnmount: false,
  validate,
  // onSubmitSuccess: submitSuccessCallback
})(ProfileForm);

export default connect(mapStateToProps, { editProfile })(wrappedForm);
