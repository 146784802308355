import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { removeLeaseNote, getLeaseNotes } from "../../../actions";

import { Card, Modal, List, Message } from "semantic-ui-react";
import Moment from "react-moment";

import { Switch, Route, Link } from "react-router-dom";

import Loading from "../../../Components/Loading";
import NewNoteModal from "../../../Components/Modals/NewNotes";
import NoteCard from "../../../Components/NoteCard";
const LeaseNotes = ({ activeLease }) => {
  // const activeLease = useSelector((state) => state.leases.lease);

  const notes = useSelector((state) => state.leases.lease.notes);

  const loadingNotes = useSelector((state) => state.leases.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLeaseNotes(activeLease.id));
  }, [dispatch, activeLease.id]);

  const deleteNoteAction = (id) => dispatch(removeLeaseNote(id));

  const renderLoading = (
    <Loading nameOfResource="Lease Notes" message="Please wait..." />
  );

  const renderNotes =
    activeLease["notes"] && Object.keys(notes).length ? (
      <Card fluid>
        <Card.Content>
          <List divided relaxed>
            {Object.values(notes).map((note) => (
              <NoteCard note={note} deleteNoteAction={deleteNoteAction} />
            ))}
          </List>
        </Card.Content>
      </Card>
    ) : (
      <Message content="There are no notes to display" />
    );

  return (
    <>
      <NewNoteModal
        activeLease={activeLease}
        noteParentType="lease_id"
        noteParentId={activeLease.id}
        headerTitle="Lease Notes"
        loading={loadingNotes}
      />
      {loadingNotes ? renderLoading : renderNotes}
    </>
  );
};

export default LeaseNotes;
