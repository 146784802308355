import _ from "lodash";

import {
  CANCEL_LEASE,
  DELETE_UNIT,
  DELETE_UNIT_NOTE,
  FETCH_USER_UNITS,
  FETCH_UNIT_DETAILS,
  EDIT_UNIT,
  POST_NEW_UNIT,
  LOADING_UNIT,
  LOADING_UNIT_COMPLETE,
  ADD_UNIT_NOTE,
  FETCH_ACTIVE_UNITS,
  FETCH_UNIT_NOTES,
  FETCH_SELECTED_UNIT_LEASES,
  POST_LEASE,
  CLEAR_UNIT,
  USER_SIGN_OUT,
} from "../actions/types";

const INITIAL_STATE = {
  isLoading: false,
  userUnits: {},
  activeUnits: {},
  selectedUnit: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_UNIT:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_UNIT_COMPLETE:
      return {
        ...state,
        isLoading: false,
      };

    case CLEAR_UNIT:
      return {
        ...state,
        selectedUnit: {},
      };
    case FETCH_USER_UNITS:
      return {
        ...state,

        userUnits: { ..._.mapKeys(action.payload, "id") },
      };

    case FETCH_ACTIVE_UNITS:
      return {
        ...state,
        activeUnits: { ..._.mapKeys(action.payload, "id") },
      };

    case FETCH_UNIT_DETAILS:
      return {
        ...state,
        selectedUnit: {
          ...action.payload,
        },
      };

    // case POST_LEASE:
    //   return {
    //     ...state,
    //     userUnits: {
    //       ...state.userUnits,
    //       [action.payload.unit_id]: {
    //         ...state.userUnits[action.payload.unit_id],
    //         active_lease_id: action.payload.id,
    //       },
    //     },
    //   };

    case CANCEL_LEASE:
      return {
        ...state,
        selectedUnit: {
          ...state.selectedUnit,
          active_lease_id: null,
        },
      };

    case POST_NEW_UNIT:
      return {
        ...state,
        userUnits: {
          ...state.userUnits,
          [action.payload.id]: action.payload,
        },
      };
    case DELETE_UNIT:
      console.log("PAYLOAD", action.payload);
      return {
        ...state,
        userUnits: {
          ..._.omit(state.userUnits, action.payload),
        },
      };

    case DELETE_UNIT_NOTE:
      return {
        ...state,
        selectedUnit: {
          ...state.selectedUnit,
          notes: {
            ..._.omit(state.selectedUnit.notes, action.payload),
          },
        },
      };
    case FETCH_SELECTED_UNIT_LEASES:
      return {
        ...state,
        selectedUnit: {
          ...state.selectedUnit,
          pastLeases: { ..._.mapKeys(action.payload, "id") },
        },
      };

    case FETCH_UNIT_NOTES:
      return {
        ...state,
        selectedUnit: {
          ...state.selectedUnit,
          notes: { ..._.mapKeys(action.payload, "id") },
        },
      };

    case EDIT_UNIT:
      return {
        ...state,
        selectedUnit: action.payload,
      };

    case ADD_UNIT_NOTE:
      return {
        ...state,

        selectedUnit: {
          ...state.selectedUnit,
          notes: {
            ...state.selectedUnit.notes,
            [action.payload.id]: action.payload,
          },
        },
      };

    case USER_SIGN_OUT:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
