import {
  FETCH_LEASE_REQUESTS,
  UPDATE_LEASE_REQUEST,
  LOADING_LEASE_REQUEST,
  LOADING_LEASE_REQUEST_COMPLETE,
  CANCEL_LEASE,
  USER_SIGN_OUT,
} from "../actions/types";

import _ from "lodash";

const INITIAL_STATE = {
  isLoading: false,
  requests: {},
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_LEASE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_LEASE_REQUEST_COMPLETE:
      return {
        ...state,
        isLoading: false,
      };
    //
    // case CANCEL_LEASE:
    //   return {
    //     ...state,
    //     lease: {},
    //   };
    case FETCH_LEASE_REQUESTS:
      return {
        ...state,
        requests: { ..._.mapKeys(action.payload, "id") },
      };

    case UPDATE_LEASE_REQUEST:
      const { [action.payload.id]: payload, ...rest } = state.requests;

      return {
        ...state,
        requests: {
          ...rest,
        },
      };

    case USER_SIGN_OUT:
      return {
        ...state,
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};
