import React, { Component } from "react";
import {
  reduxForm,
  Field,
  destroy,
  change,
  SubmissionError,
  getFormSyncErrors,
  getFormMeta,
} from "redux-form";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { createNewProperty } from "../../actions";

import { Form, Button, Header, Label } from "semantic-ui-react";
import { colors } from "../../Styles";

import FormConfirmation from "./FormConfirmation";
import AddressField from "./AddressField";

//No hooks for redux-forms; must use HOC

class NewPropertyForm extends Component {
  state = { page: 1 };

  renderError = ({ error, touched }) => {
    if (error && touched) {
      return {
        content: error,
        pointing: "above",
      };
    }
  };

  displayError = ({ error, touched }) => {
    if (error && touched) {
      return (
        <Label
          basic
          style={{ color: "#9f3a38", border: "1px solid #e0b4b4" }}
          pointing
        >
          {error}
        </Label>
      );
    }
  };

  renderAddressInput = ({ placeholder, label, type, input, meta }) => {
    return (
      <div>
        {" "}
        <AddressField
          {...input}
          name="address"
          label={label}
          placeholder={placeholder}
        />
        {this.displayError(meta, input)}
      </div>
    );
  };

  renderInput = ({ placeholder, label, type, input, meta }) => {
    return (
      <Form.Input
        {...input}
        error={this.renderError(meta)}
        label={label}
        placeholder={placeholder}
        value={input.value}
      />
    );
  };

  nextPage = () => this.setState({ page: this.state.page + 1 });

  previousPage = () => this.setState({ page: this.state.page - 1 });

  submitNewProperty = (formData) => {
    return this.props
      .createNewProperty(formData)
      .then(() => this.props.history.push("properties"))
      .catch((err) => {
        this.previousPage();
        this.props.clearSubmitErrors();
        throw new SubmissionError({
          address: "This address has already been used",
        });
      });
  };

  render() {
    const { page } = this.state;
    return (
      <>
        <Header>
          New Property
          <Header.Subheader>
            Add a new property. You can add units to the property later.
          </Header.Subheader>
        </Header>
        <Form onSubmit={this.props.handleSubmit(this.submitNewProperty)}>
          {page === 1 && (
            <>
              <Field
                name="name"
                component={this.renderInput}
                placeholder="Property Name"
                label="Name"
                type="text"
              />
              <Field
                name="address"
                component={this.renderAddressInput}
                placeholder="Street Address"
                label="Address"
                type="text"
              />

              <Button
                style={colors.inactive}
                disabled={this.props.invalid ? true : false}
                onClick={() => this.nextPage()}
                content="Next"
                icon="right arrow"
                labelPosition="right"
              />
            </>
          )}

          {page === 2 && (
            <FormConfirmation
              previousPage={this.previousPage}
              formName="newpropertyForm"
              submitButtonName="Save Property"
              formHeader="New Property Info"
              formObj={this.props.formObj}
              loading={this.props.loading}
            />
          )}
        </Form>
      </>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.address) {
    errors.address = "Please enter property address";
  }
  if (!formValues.name) {
    errors.name = "Please enter a name for your property";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    formObj: state.form.newpropertyForm,
    loading: state.properties.isLoading,
  };
};

const submitSuccessCallback = (result, dispatch) => {
  return dispatch(destroy("newpropertyForm"));
};

const wrappedForm = reduxForm({
  form: "newpropertyForm",
  destroyOnUnmount: false,
  touchOnChange: true,
  touchOnBlur: true,
  validate,

  onSubmitSuccess: submitSuccessCallback,
})(NewPropertyForm);

const connectedForm = connect(mapStateToProps, { createNewProperty })(
  wrappedForm
);

export default withRouter(connectedForm);
