import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTenantActiveLease } from "../../actions";

import { Switch, Route, Link } from "react-router-dom";

import { Menu, Container } from "semantic-ui-react";

import LeaseRequests from "./LeaseRequests";
import ActiveLease from "./ActiveLease";
import PastLeases from "./PastLeases";
import LeaseReview from "./LeaseReview";

import MaintenanceTickets from "./MaintenanceTickets";
import Account from "./Account";

const Overview = (props) => {
  const [state, setState] = useState({});
  const dispatch = useDispatch();

  //After container loads, the state is set to current pathname
  const { pathname } = props.location;
  const { url, params } = props.match;

  useEffect(() => {
    setState({ activeItem: pathname });
  }, [pathname]);

  // useEffect(() => {
  //   dispatch(getTenantActiveLease());
  // }, [dispatch]);

  const { activeItem } = state;

  return (
    <Container>
      <Menu pointing secondary>
        <Menu.Item
          name="activeLease"
          as={Link}
          to={`${url}/active-lease`}
          active={activeItem === "/tenant/active-lease"}
        />
        <Menu.Item
          name="maintenanceTickets"
          as={Link}
          to={`${url}/maintenance-tickets`}
          active={activeItem === "/tenant/maintenance-tickets"}
        />
        <Menu.Item
          name="Requests"
          as={Link}
          to={`${url}/lease-requests`}
          active={activeItem === "/tenant/lease-requests"}
        />

        <Menu.Item
          position="right"
          name="Account"
          as={Link}
          to={`${url}/account`}
          active={activeItem === "/tenant/account"}
        />
      </Menu>

      <Switch>
        <Route
          path="/tenant/lease-requests/review-lease/:id"
          component={LeaseReview}
        />
        <Route path="/tenant/lease-requests" component={LeaseRequests} />
        <Route path="/tenant/active-lease" component={ActiveLease} />
        <Route path="/tenant/leases" component={PastLeases} />

        <Route
          path="/tenant/maintenance-tickets"
          component={MaintenanceTickets}
        />
        <Route path="/tenant/account" component={Account} />
      </Switch>
    </Container>
  );
};

export default Overview;
