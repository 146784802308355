import React, { useState, useEffect } from "react";
import { Grid, Menu, Segment } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";

/**
 * Hide on small or mobile and display hamburger isntead
 */

function SideNavbar() {
  const location = useLocation();
  const [state, setState] = useState({ activeItem: location.pathname });

  useEffect(() => {
    setState({ activeItem: location.pathname });
  }, [location]);

  const { activeItem } = state;

  return (
    <>
      <Menu
        fluid
        pointing
        secondary
        vertical
        left
        style={{ textAlign: "right" }}
      >
        <Menu.Item
          name="Feed"
          active={activeItem === `/landlord/feed`}
          as={Link}
          to={`/landlord/feed`}
        />
        <Menu.Item
          name="Properties"
          active={activeItem === `/landlord/properties`}
          as={Link}
          to={`/landlord/properties`}
        />
        <Menu.Item
          name="Units"
          active={activeItem === `/landlord/units`}
          as={Link}
          to={`/landlord/units`}
        />

        <br />
        <Menu.Item
          name="Account"
          active={activeItem === `/landlord/account`}
          as={Link}
          to={`/landlord/account`}
        />
      </Menu>
    </>
  );
}

export default SideNavbar;
