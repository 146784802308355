import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { removeUnit } from "../../../actions";

import { Link, Route, Switch, useHistory } from "react-router-dom";
import { Icon, Header, Menu, Message, Card, Button } from "semantic-ui-react";

import UnitListView from "../../../Components/propertyDetails/UnitList";
import UnitCard from "../../../Components/UnitCard";
import Loading from "../../../Components/Loading";

const UnitsTab = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const property = useSelector((state) => state.properties.selectedProperty);

  const allUnits = useSelector((state) => Object.values(state.units.userUnits));

  const units = Object.values(allUnits).filter(
    (unit) => unit.property.id === property.id
  );

  const isLoading = useSelector((state) => state.app.loading);

  const { pathname } = props.location;
  const { url, params } = props.match;
  console.log("PARAMS", props.match);
  console.log("LCOATION", props.location);

  const removeUnitAction = (id) => {
    return dispatch(removeUnit(id)).then(() => history.push("/landlord/units"));
  };

  const loading = <Loading nameOfResource="Units" />;

  const noUnits = (
    <Message className="no-resource">
      <Header as="h2">
        <Icon name="cogs" />
      </Header>
      <Message.Content>
        <Message.Header>There are no units for this property</Message.Header>
        You have not added any units yet. Please start by{" "}
        <Link to={`${url}/add-unit-form`}>adding a unit</Link>.
      </Message.Content>
    </Message>
  );

  const renderedUnits = units.map((unit) => (
    <UnitCard
      key={unit.id}
      unit={unit}
      property={property}
      propertyId={params.id}
    />
  ));

  return (
    <>
      <Header
        as="h5"
        style={{ display: "inline-block" }}
        content={`There are ${units.length} units in this property`}
      />

      <Button
        floated="right"
        className="action-button"
        as={Link}
        to={`${url}/add-unit-form`}
        content="Unit"
        icon="plus"
        labelPosition="left"
      />

      <Card.Group itemsPerRow={4} doubling>
        {isLoading ? loading : units.length === 0 ? noUnits : renderedUnits}
      </Card.Group>
    </>
  );
};

export default UnitsTab;
