import React from "react";
import { Link } from "react-router-dom";

import { Button, Grid, Dropdown } from "semantic-ui-react";

const FABMenu = () => (
  <Grid.Column style={style.grid} only="mobile" width={2}>
    <Dropdown
      as={Button}
      className="FABMenu"
      icon="align justify"
      pointing="top right"
      style={style.button}
      upward
    >
      <Dropdown.Menu>
        <Dropdown.Item text="Feed" as={Link} to={`/landlord/feed`} />
        <Dropdown.Item
          text="Properties"
          as={Link}
          to={`/landlord/properties`}
        />
        <Dropdown.Item text="Units" as={Link} to={`/landlord/units`} />

        <Dropdown.Divider />
        <Dropdown.Item text="Account" as={Link} to={`/landlord/account`} />
      </Dropdown.Menu>
    </Dropdown>
  </Grid.Column>
);

const style = {
  grid: {
    position: "fixed",
    bottom: "0",
    right: "0",
    zIndex: "999",
    height: "15vh",
  },
  button: {
    borderRadius: "100%",
    color: "white",
    float: "right",
    height: "4rem",
    width: "4rem",
    // height: "12vw",
    // width: "12vw",
  },
};
export default FABMenu;
