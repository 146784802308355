import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getLeaseDetails, respondToLeaseRequest } from "../../actions";

import { Header, Button } from "semantic-ui-react";

import LeaseCard from "../../Components/LeaseCard";
import LeaseRequestCard from "../../Components/LeaseRequestCards";

import Loading from "../../Components/Loading";

const LeaseReview = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tenantProfile = useSelector((state) => state.auth.tenant_profile);

  const lease = useSelector((state) => state.leases.lease);

  const isLoading = useSelector((state) => state.leases.isLoading);

  const { match, location } = props;

  useEffect(() => {
    dispatch(getLeaseDetails(match.params.id));
  }, [dispatch]);

  const handleLeaseRequestUpdate = (answer) => {
    const leaseRequest = lease.lease_requests.find(
      ({ tenant_profile }) =>
        tenant_profile.unique_number === tenantProfile.unique_number
    );
    leaseRequest.accepted = answer;

    return dispatch(respondToLeaseRequest(leaseRequest)).then(() =>
      history.push("/tenant/lease-requests")
    );
  };

  const loading = <Loading nameOfResource="Lease details" />;

  const renderLease = Object.keys(lease).length && <LeaseCard lease={lease} />;

  return (
    <>
      <Header content="Lease Request Details" />
      <Button content="accept" onClick={() => handleLeaseRequestUpdate(true)} />
      <Button
        content="Decline"
        onClick={() => handleLeaseRequestUpdate(false)}
      />

      {isLoading ? loading : renderLease}
    </>
  );
};

export default LeaseReview;
