import React, { Component } from "react";
import { reduxForm, Field, destroy, SubmissionError } from "redux-form";
import { connect } from "react-redux";

import { createNewNote } from "../../actions";

import { Form, Button, Header, Divider } from "semantic-ui-react";
import { colors } from "../../Styles";

import FormConfirmation from "./FormConfirmation";

//No hooks for redux-forms; must use HOC

class NewNoteForm extends Component {
  state = { page: 1 };

  renderError = ({ error, touched }) => {
    if (error && touched) {
      return {
        content: error,
        pointing: "above",
      };
    }
  };

  renderTextArea = ({ placeholder, label, type, input, meta }) => {
    return (
      <Form.TextArea
        {...input}
        error={this.renderError(meta)}
        label={label}
        placeholder={placeholder}
        value={input.value}
      />
    );
  };

  renderInput = ({ placeholder, label, type, input, meta }) => {
    return (
      <Form.Input
        {...input}
        error={this.renderError(meta)}
        label={label}
        placeholder={placeholder}
        value={input.value}
      />
    );
  };

  nextPage = () => this.setState({ page: this.state.page + 1 });

  previousPage = () => this.setState({ page: this.state.page - 1 });

  submitNewProperty = (formData) => {
    const type = `${this.props.noteParentType}`;
    const id = this.props.noteParentId;

    //massage formData to have type attribute of 'lease_id, unit_id, property_id' and assign id
    formData[type] = id;

    return this.props
      .createNewNote(formData, type)
      .then(() => this.props.closeModal())
      .catch((error) => {
        throw new SubmissionError();
      });
  };

  render() {
    const { page } = this.state;

    return (
      <>
        <Header>
          New Note
          <Header.Subheader>
            You can add a note. Notes are helpful in reminding you about
            miscellanious information. You can add as many notes as you want.
          </Header.Subheader>
        </Header>
        <Divider hidden />
        <Form onSubmit={this.props.handleSubmit(this.submitNewProperty)}>
          {page === 1 && (
            <>
              <Field
                name="title"
                component={this.renderInput}
                placeholder="Note title"
                label="Title"
                type="text"
              />
              <Field
                name="description"
                component={this.renderTextArea}
                placeholder="Write your note here"
                label="Description"
                type="text"
              />
              <Button
                style={colors.inactive}
                disabled={this.props.invalid ? true : false}
                onClick={() => this.nextPage()}
                content="Next"
                icon="right arrow"
                labelPosition="right"
              />
            </>
          )}

          {page === 2 && (
            <FormConfirmation
              previousPage={this.previousPage}
              formName="NewNoteForm"
              submitButtonName="Save Note"
              formHeader="New Note"
              formObj={this.props.formObj}
              loading={this.props.loading}
            />
          )}
        </Form>
      </>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.title) {
    errors.title = "Title is required";
  }
  if (!formValues.description) {
    errors.description = "Description is required";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    formObj: state.form.NewNoteForm,
    // loading: state.properties.loading
  };
};

const submitSuccessCallback = (result, dispatch) => {
  return dispatch(destroy("NewNoteForm"));
};

const wrappedForm = reduxForm({
  form: "NewNoteForm",
  destroyOnUnmount: false,
  validate,
  onSubmitSuccess: submitSuccessCallback,
})(NewNoteForm);

export default connect(mapStateToProps, { createNewNote })(wrappedForm);
