export const LOADING = "LOADING";
export const LOADING_COMPLETE = "LOADING_COMPLETE";
export const LOADING_LEASE = "LOADING_LEASE";
export const LOADING_LEASE_COMPLETE = "LOADING_LEASE_COMPLETE";
export const LOADING_UNIT = "LOADING_UNIT";
export const LOADING_UNIT_COMPLETE = "LOADING_UNIT_COMPLETE";
export const LOADING_PROPERTY = "LOADING_PROPERTY";
export const LOADING_PROPERTY_COMPLETE = "LOADING_PROPERTY_COMPLETE";
export const LOADING_MAINTENANCE_REQUEST = "LOADING_MAINTENANCE_REQUEST";
export const LOADING_MAINTENANCE_REQUEST_COMPLETE =
  "LOADING_MAINTENANCE_REQUEST_COMPLETE";
export const LOADING_NOTES = "LOADING_NOTES";
export const LOADING_NOTES_COMPLETE = "LOADING_NOTES_COMPLETE";
export const LOADING_LEASE_REQUEST = "LOADING_LEASE_REQUEST";
export const LOADING_LEASE_REQUEST_COMPLETE = "LOADING_LEASE_REQUEST_COMPLETE";
export const LOADING_TENANT = "LOADING_TENANT";
export const LOADING_TENANT_COMPLETE = "LOADING_TENANT_COMPLETE";

export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
export const REQUEST_FAILED = "REQUEST_FAILED";
export const SUCCESS_POPUP = "SUCCESS_POPUP";
export const FAILURE_POPUP = "FAILURE_POPUP";
export const RESET_POP_UP = "RESET_POP_UP";

//PROPERTIES
export const FETCH_USER_PROPERTIES = "FETCH_USER_PROPERTIES";
export const FETCH_ALL_PROPERTIES = "FETCH_ALL_PROPERTIES";
export const FETCH_PROPERTY_DETAILS = "FETCH_PROPERTY_DETAILS";
export const POST_NEW_PROPERTY = "POST_NEW_PROPERTY";
export const DELETE_PROPERTY = "DELETE_PROPERTY";
export const ADD_PROPERTY_NOTE = "ADD_PROPERTY_NOTE";
export const DELETE_PROPERTY_NOTE = "DELETE_PROPERTY_NOTE";
export const EDIT_PROPERTY = "EDIT_PROPERTY";

export const SET_SELECTED_PROPERTY = "SET_SELECTED_PROPERTY";
export const POST_UNIT_TO_PROPERTY = "POST_UNIT_TO_PROPERTY";

//UNITS
export const EDIT_UNIT = "EDIT_UNIT";
export const FETCH_ACTIVE_UNITS = "FETCH_ACTIVE_UNITS";
export const FETCH_USER_UNITS = "FETCH_USER_UNITS";
export const FETCH_UNIT_DETAILS = "FETCH_UNIT_DETAILS";
export const FETCH_UNIT_NOTES = "FETCH_UNIT_NOTES";
export const POST_NEW_UNIT = "POST_NEW_UNIT";
export const DELETE_UNIT = "DELETE_UNIT";
export const ADD_UNIT_NOTE = "ADD_UNIT_NOTE";
export const DELETE_LEASE_NOTE = "DELETE_LEASE_NOTE";
export const CLEAR_UNIT = "CLEAR_UNIT";

//LEASE
export const CLEAR_ACTIVE_LEASE = "CLEAR_ACTIVE_LEASE";
export const FETCH_SELECTED_UNIT_ACTIVE_LEASE =
  "FETCH_SELECTED_UNIT_ACTIVE_LEASE";
export const FETCH_SELECTED_UNIT_LEASES = "FETCH_SELECTED_UNIT_LEASES";
export const FETCH_USER_LEASES = "FETCH_USER_LEASES";
export const FETCH_ACTIVE_LEASES = "FETCH_ACTIVE_LEASES";
export const FETCH_LEASE_DETAILS = "FETCH_LEASE_DETAILS";
export const FETCH_LEASE = "FETCH_LEASE";
export const FETCH_LEASE_NOTES = "FETCH_LEASE_NOTES";
export const SET_UNIT_LEASES = "SET_UNIT_LEASES";
export const EDIT_LEASE_TENANT_PROFILE = "EDIT_LEASE_TENANT_PROFILE";

export const POST_LEASE = "POST_LEASE";
export const DELETE_LEASE = "DELETE_LEASE";
export const SET_ACTIVE_LEASE = "SET_ACTIVE_LEASE";
export const SET_SELECTED_UNIT_ACTIVE_LEASE = "SET_SELECTED_UNIT_ACTIVE_LEASE";
export const ADD_LEASE_NOTE = "ADD_LEASE_NOTE";
export const DELETE_UNIT_NOTE = "DELETE_UNIT_NOTE";
export const UPDATE_LEASE = "UPDATE_LEASE";

export const CANCEL_LEASE = "CANCEL_LEASE";
export const MAKE_ACTIVE = "MAKE_ACTIVE";

//TENANT
export const FETCH_ALL_TENANTS = "FETCH_ALL_TENANTS";
export const FETCH_TENANT = "FETCH_TENANT";
export const DELETE_TENANT = "DELETE_TENANT";

export const FETCH_ACTIVE_MAINTENANCE_REQUESTS =
  "FETCH_ACTIVE_MAINTENANCE_REQUESTS";
export const FETCH_ALL_MAINTENACE_CURRENT_LEASE =
  "FETCH_ALL_MAINTENACE_CURRENT_LEASE";
export const CLEAR_MAINTENANCE_REQESTS = "CLEAR_MAINTENANCE_REQESTS";
export const CREATE_MAINTENANCE_REQ = "CREATE_MAINTENANCE_REQ";
export const CLOSE_MAINTENANCE = "CLOSE_MAINTENANCE";

export const EDIT_LANDLORD_PROFILE = "EDIT_LANDLORD_PROFILE";

export const USER_SIGN_IN = "USER_SIGN_IN";
export const USER_SIGN_OUT = "USER_SIGN_OUT";
export const USER_SIGN_UP = "USER_SIGN_UP";

//TENANT ACTIONS TYPES

export const FETCH_LEASE_REQUESTS = "FETCH_LEASE_REQUESTS";
export const UPDATE_LEASE_REQUEST = "UPDATE_LEASE_REQUEST";
export const DELETE_LEASE_REQUEST = "DELETE_LEASE_REQUEST";

export const FETCH_TENANT_LEASES = "FETCH_TENANT_LEASES";
export const FETCH_TENANT_ACTIVE_LEASE = "FETCH_TENANT_ACTIVE_LEASE";

export const EDIT_TENANT_PROFILE = "EDIT_TENANT_PROFILE";
