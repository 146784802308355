import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPopUp } from "../actions";

import {
  Button,
  Grid,
  Header,
  Popup,
  Segment,
  Message,
} from "semantic-ui-react";

// IDEA: Consider using Portals instead; it will help prevent zindex issues
const PopMessage = (props) => {
  const timeoutLength = 3000;

  const { popUpOpened, popUpMessage, backgroundColor } = useSelector(
    (state) => state.app
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(resetPopUp());
    }, timeoutLength);

    return () => clearTimeout(timer);
  }, [popUpOpened, popUpMessage]);

  const styles = {
    grid: {
      position: "absolute",
    },
    mobileRow: {
      position: "fixed",
      left: 0,
      bottom: 0,
      right: 0,
      padding: 0,
      zIndex: 1001,
    },
    computerRow: {
      // display: "flex",
      // flexFlow: "row-reverse wrap",
      // width: "10rem",
      position: "fixed",
      top: "1rem",
      zIndex: 1001,
    },
    message: {
      backgroundColor: backgroundColor,
      borderRadius: 0,
      color: "white",
    },
  };
  const popupMessage = <Message style={styles.message}>{popUpMessage}</Message>;

  const displayPopUp = (
    <Grid className="container" relaxed style={styles.grid}>
      <Grid.Column
        textAlign="center"
        computer={4}
        widescreen={6}
        style={styles.computerRow}
        only="computer"
      >
        {popupMessage}
      </Grid.Column>

      <Grid.Row style={styles.mobileRow} only="tablet mobile">
        <Grid.Column style={{ padding: 0 }}>{popupMessage}</Grid.Column>
      </Grid.Row>
    </Grid>
  );

  return popUpOpened && displayPopUp;
};

export default PopMessage;
