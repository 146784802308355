import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, submit } from "redux-form";
import { Link, useHistory } from "react-router-dom";

import { removeUnit } from "../../../actions";

import { Icon, Header, Menu, Table, Button } from "semantic-ui-react";

// import PropertyNameField from "../../Forms/Fields/PropertyNameInput";
// import SettingsTab from "../../../Components/SettingsTab";

const Settings = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const unit = useSelector((state) => state.units.selectedUnit);

  const handleDeleteUnit = () => {
    return dispatch(removeUnit(unit.id)).then(() =>
      history.push(`/landlord/properties/${params.property_id}`)
    );
  };

  const { pathname } = props.location;
  const { url, params } = props.match;

  return (
    <>
      <Button
        fluid
        negative
        icon="trash"
        content="Delete Unit"
        onClick={() => handleDeleteUnit()}
      />
    </>
  );
};

export default Settings;
