import React, { Component } from "react";
import { reduxForm, Field, destroy, change } from "redux-form";
import { connect } from "react-redux";

import { editUnit } from "../../../actions";

import { Form, Button, Header, Item } from "semantic-ui-react";

//No hooks for redux-forms; must use HOC

class UnitInfoFields extends Component {
  renderError = ({ error, touched }) => {
    if (error && touched) {
      return {
        content: error,
        pointing: "above",
      };
    }
  };

  renderInput = ({ placeholder, label, type, input, meta }) => {
    return (
      <Form.Input
        size="mini"
        {...input}
        type={type}
        error={this.renderError(meta)}
        placeholder={placeholder}
        value={input.value}
      />
    );
  };

  submitEdit = async (formData) => {
    console.log("FORM DATA", formData);
    // await this.props
    //   .editPropertyAction(formData)
    //   .then(() => this.props.dispatch(destroy("UnitInfoFields")));
  };

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <Item.Group divided>
          <Item>
            <Item.Content>
              Bedrooms:{" "}
              <Field
                name="bed_num"
                component={this.renderInput}
                placeholder="Number of bedrooms"
                type="number"
              />
            </Item.Content>
            <Item.Content>
              Bathrooms:
              <Field
                name="bath_num"
                component={this.renderInput}
                placeholder="Number of bedrooms"
                type="text"
              />
            </Item.Content>
            <Item.Content>
              Parking Spaces:{" "}
              <Field
                name="parking_num"
                component={this.renderInput}
                placeholder="Number of Parking"
                type="number"
              />
            </Item.Content>
          </Item>
        </Item.Group>
      </Form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.bed_num) {
    errors.bed_num = "Number of bedrooms";
  }
  if (!formValues.bath_num) {
    errors.bath_num = "Number of bathrooms";
  }

  return errors;
};

const mapStateToProps = (state, ownProps) => {
  const { id, bed_num, bath_num, parking_num } = ownProps.unitDetails;
  return {
    onSubmit: ownProps.handleEditSubmit,
    initialValues: { id, bed_num, bath_num, parking_num },
    loading: state.properties.loading,
  };
};

const submitSuccessCallback = (result, dispatch) => {
  return dispatch(destroy("UnitInfoFields"));
};

const wrappedForm = reduxForm({
  form: "UnitInfoFields",
  destroyOnUnmount: false,
  validate,
  onSubmitSuccess: submitSuccessCallback,
})(UnitInfoFields);

export default connect(mapStateToProps, { editUnit })(wrappedForm);
