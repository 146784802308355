import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { Header, Button } from "semantic-ui-react";

import { signOut } from "../../actions";

import ProfileView from "../../Components/ProfileView";

function Account() {
  const dispatch = useDispatch();

  const { landlord_profile, email } = useSelector((state) => state.auth);

  const handleUserSignOut = () => dispatch(signOut());

  const {
    first_name,
    last_name,
    phone,
    user_name,
    unique_number,
    created_at,
  } = landlord_profile;

  return (
    <>
      <Header content="Account" />
      <ProfileView
        profileInfo={landlord_profile}
        profileType="Landlord"
        email={email}
      />
      <Button
        floated="right"
        onClick={() => handleUserSignOut()}
        content="Sign out"
      />
    </>
  );
}

export default Account;
