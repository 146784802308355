export const colors = {
  inactive: {
    backgroundColor: '#cfd8dc'
  },
  actionButton: {
    backgroundColor: '#00796b',
    color: 'white'
  },
  confirmButton: {
    backgroundColor: '#00bfa5'
  },
  whiteFont: {
    color: '#0000'
  }
}

export const nav = {
  menu: {
    color: 'white',
    height: '5rem',
    marginBottom: '3rem',
    backgroundColor: '#00897b',
  },
  item: {
    height: '100%'
  },
  hidden: {
    display: 'none'
  }
};
