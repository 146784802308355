import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PropertyCard from "../../../Components/PropertyCard";
import Loading from "../../../Components/Loading";
import UnitCard from "../../../Components/UnitCard";

import { getAllUserProperties, removeProperty } from "../../../actions";

import {
  Header,
  Icon,
  Segment,
  Button,
  Message,
  Card,
  Item,
  Label,
} from "semantic-ui-react";

function Properties(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUserProperties());
  }, [dispatch]);

  const properties = useSelector((state) =>
    Object.values(state.properties.userProperties)
  );

  const units = useSelector((state) => Object.values(state.units.userUnits));

  const isLoading = useSelector((state) => state.properties.isLoading);

  const handleDeleteProperty = (id) => {
    dispatch(removeProperty(id));
  };

  const loading = <Loading nameOfResource="Properties" />;

  const noProperties = (
    <Message className="center-align" icon>
      <Icon name="cogs" />
      <Message.Content>
        <Message.Header>There are no properties to display</Message.Header>
        <Link to="add-property-form">Add a new property</Link> to begin.
      </Message.Content>
    </Message>
  );

  const renderUnitItems = (units) =>
    units.map((unit) => <UnitCard unit={unit} propertyId={unit.property.id} />);

  const renderedProperties = Object.values(properties).map((property) => {
    let propertyUnits = Object.values(
      units.filter((unit) => unit.property.id === property.id)
    );
    return (
      <PropertyCard
        key={property.id}
        property={property}
        handleDeleteProperty={handleDeleteProperty}
      >
        {propertyUnits.length ? (
          <Card.Group doubling itemsPerRow={4}>
            {renderUnitItems(propertyUnits)}
          </Card.Group>
        ) : (
          <p>No Units have been added yet</p>
        )}
      </PropertyCard>
    );
  });

  return (
    <>
      <div
        style={{
          display: "inline-flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Header icon>
          <Header.Content>All Your Properties</Header.Content>
        </Header>

        <Button
          className="action-button"
          as={Link}
          to="add-property-form"
          primary
        >
          New Property
        </Button>
      </div>

      <Card.Group itemsPerRow={1} stackable doubling>
        {isLoading
          ? loading
          : properties.length === 0
          ? noProperties
          : renderedProperties}
      </Card.Group>
    </>
  );
}

export default Properties;
