import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { signOut } from "../actions";

import NavBar from "../Components/Navigation";
// import PopUpMessage from "../Components/PopUpMessages";

function NavStateWrapper() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleUserSignOut = () => {
    dispatch(signOut());
  };

  return (
    <>
      <NavBar auth={auth} />
    </>
  );
}

export default NavStateWrapper;
