import {
  api,
  fetchTenantActiveLease,
  fetchTenantLeases,
  fetchMaintenanceForLease,
  fetchPendingLeaseRequests,
  updateTenantProfile,
  updateLeaseRequest,
  fetchLeaseDetails,
} from "./api";

import {
  successPopUp,
  failurePopUp,
  requestSuccess,
  requestFailed,
} from "./index";

import { history } from "../index";

import {
  LOADING_MAINTENANCE_REQUEST,
  LOADING_LEASE_COMPLETE,
  LOADING_LEASE_REQUEST_COMPLETE,
  LOADING_COMPLETE,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
  FETCH_LEASE_REQUESTS,
  UPDATE_LEASE_REQUEST,
  FETCH_LEASE,
  FETCH_TENANT_LEASES,
  FETCH_TENANT_ACTIVE_LEASE,
  FETCH_ALL_MAINTENACE_CURRENT_LEASE,
  CLOSE_MAINTENANCE,
  EDIT_TENANT_PROFILE,
} from "./types";

export const editTenantProfile = (profile) => async (dispatch, getState) => {
  return dispatch(updateTenantProfile(profile))
    .then((response) =>
      dispatch(
        requestSuccess(EDIT_TENANT_PROFILE, response.data.tenant_profile)
      )
    )
    .then(() => dispatch(successPopUp("Profile updated!")))
    .then((response) => dispatch({ type: LOADING_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error, LOADING_COMPLETE)));
};

export const getPendingLeaseRequests = () => async (dispatch, getState) => {
  return dispatch(fetchPendingLeaseRequests())
    .then((response) =>
      dispatch(
        requestSuccess(FETCH_LEASE_REQUESTS, response.data.lease_requests)
      )
    )
    .then((response) => dispatch({ type: LOADING_LEASE_REQUEST_COMPLETE }))
    .catch((error) =>
      dispatch(requestFailed(error, LOADING_LEASE_REQUEST_COMPLETE))
    );
};

export const respondToLeaseRequest = (lease_request) => (dispatch) => {
  return dispatch(updateLeaseRequest(lease_request))
    .then((response) =>
      dispatch(
        requestSuccess(UPDATE_LEASE_REQUEST, response.data.lease_request)
      )
    )
    .then(() => dispatch({ type: LOADING_LEASE_REQUEST_COMPLETE }))
    .catch((error) => {
      dispatch(failurePopUp("You already have an active Lease"));
      dispatch(requestFailed(error, LOADING_LEASE_REQUEST_COMPLETE));
    });
};

//LEASE ACTIONS

// export const getLeaseDetails = (id) => (dispatch) => {
//   return dispatch(fetchLeaseDetails(id))
//     .then((response) => {
//       dispatch(requestSuccess(FETCH_LEASE, response.data.lease));
//     })
//     .then((response) => dispatch({ type: LOADING_LEASE_COMPLETE }))
//     .catch((error) => dispatch(requestFailed(error, LOADING_LEASE_COMPLETE)));
// };

export const getTenantLeases = () => (dispatch) => {
  return dispatch(fetchTenantLeases())
    .then((response) =>
      dispatch(requestSuccess(FETCH_TENANT_LEASES, response.data.leases))
    )
    .then((response) => dispatch({ type: LOADING_LEASE_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error, LOADING_LEASE_COMPLETE)));
};

export const getTenantActiveLease = () => (dispatch) => {
  return dispatch(fetchTenantActiveLease())
    .then((response) =>
      dispatch(requestSuccess(FETCH_TENANT_ACTIVE_LEASE, response.data.leases))
    )
    .then((response) => dispatch({ type: LOADING_LEASE_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error, LOADING_LEASE_COMPLETE)));
};

export const getTenantLeaseMaintenanceRequests = (leaseId) => (dispatch) => {
  return dispatch(fetchMaintenanceForLease(leaseId))
    .then((response) =>
      dispatch(sortMaintenanceRequests(response.data.maintenance_requests))
    )
    .then((sortedRequests) => {
      console.log("REQUESTS", sortedRequests);
      dispatch(
        requestSuccess(FETCH_ALL_MAINTENACE_CURRENT_LEASE, sortedRequests)
      );
    })
    .then(() => dispatch({ type: LOADING_MAINTENANCE_REQUEST }))
    .catch((error) =>
      dispatch(requestFailed(error, LOADING_MAINTENANCE_REQUEST))
    );

  // dispatch(sortMaintenanceRequests(response.data.maintenance_requests));
};

//Cannot be accessed outside this file. Called from fetchLeaseDetails
//Filters completed and not completed tickets
const sortMaintenanceRequests = (allRequests) => (dispatch) => {
  const activeTix = allRequests.filter((tix) => tix.active);
  const completedTix = allRequests.filter((tix) => !tix.active);

  return {
    active: activeTix,
    completed: completedTix,
  };
  // dispatch({
  //   type: FETCH_ALL_MAINTENACE_CURRENT_LEASE,
  //   payload: {
  //     active: activeTix,
  //     completed: completedTix,
  //   },
  // });
};

export const completeMaintenanceRequest = (data) => async (
  dispatch,
  getState
) => {
  const maintenance_request = {
    ...data,
    active: false,
    date_completed: new Date(),
  };
  const token = getState().auth.token;
  const request = await api.put(
    `/maintenance_requests/${data.id}`,
    { maintenance_request },
    {
      headers: {
        Authorization: `Token token=${token}`,
      },
    }
  );

  dispatch({
    type: CLOSE_MAINTENANCE,
    payload: request.data.maintenance_request,
  });
};
