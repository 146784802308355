import React from "react";
import {
  Header,
  Button,
  Divider,
  Form,
  Grid,
  Segment,
} from "semantic-ui-react";

import { Link, Switch, Route } from "react-router-dom";

import LandlordSignUp from "./Forms/LandlordSignUp";
import TenantSignUp from "./Forms/TenantSignUp";
const accountType = (
  <Segment placeholder>
    <Grid columns={2} relaxed="very" unstackable="true">
      <Grid.Column>
        <Header textAlign="center" content="Manage all your properties" />
        <Button
          as={Link}
          to="/signUp/landlord-account"
          content="Landlord Account"
          size="big"
        />
      </Grid.Column>

      <Grid.Column verticalAlign="middle">
        <Header textAlign="center" content="Manage your Lease" />
        <Button
          content="Tenant Account"
          as={Link}
          to="/signUp/tenant-account"
          size="big"
        />
      </Grid.Column>
    </Grid>

    <Divider vertical>Or</Divider>
  </Segment>
);
const UserSignUp = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      justifyContent: "center",
      minHeight: "70vh",
    }}
  >
    <Header textAlign="center">
      <Header.Content as="h1">RENZY</Header.Content>
      <Header.Subheader>
        Whether you're a Landlord or a Tenant, you can manage and track your
        Lease all in one place.
      </Header.Subheader>
    </Header>

    {accountType}

    <Switch>
      <Route path="/signUp/landlord-account" component={LandlordSignUp} />
      <Route path="/signUp/tenant-account" component={TenantSignUp} />
    </Switch>
  </div>
);

export default UserSignUp;
