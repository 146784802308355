//TO DO options:
//1. refactor this form into a modal:
//  - will allow us to save on server requests.
//  - after lease is posted, then just update the state and close modal without retrieving new lease and unit data from server

import React, { Component } from "react";
import {
  reduxForm,
  Field,
  destroy,
  SubmissionError,
  FieldArray,
} from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getUnitDetails, createNewLease, getAllTenants } from "../../actions";

import { DateInput } from "semantic-ui-calendar-react";
import {
  Card,
  Icon,
  Form,
  Button,
  Header,
  Label,
  Select,
  Checkbox,
  Divider,
  Popup,
  Loader,
} from "semantic-ui-react";
import "./FormStyles.css";
import { colors } from "../../Styles";

import FormConfirmation from "./FormConfirmation";

//No hooks for redux-forms; must use HOC

class NewLeaseForm extends Component {
  state = { page: 1, firstName: "", registered: false, unregistered: false };

  //Set up if page is refreshed
  componentDidMount() {
    //If unit details isnt already set, then fetch it
    // if (!Object.keys(this.props.selectedUnit).length) {
    //   this.props.getUnitDetails(this.props.match.params.id);
    // }

    this.props.getUnitDetails(this.props.match.params.property_id);
    //to do: prevent fethcing all tenants.
    //Implement fethcing tenant on unique ID or name search
    this.props.getAllTenants();
  }

  componentWillUnmount() {
    this.props.dispatch(destroy("NewLeaseForm"));
  }

  renderError = ({ error, touched }) => {
    if (error && touched) {
      return {
        content: error,
        pointing: "above",
      };
    }
  };

  renderDatePicker = ({ placeholder, type, label, format, input, meta }) => {
    return (
      <DateInput
        {...input}
        name={input.name}
        placeholder={placeholder}
        dateFormat={"MM-DD-YYYY"}
        onChange={(e, v) => input.onChange(v.value)}
        label={label}
        value={input.value}
        closable={true}
        closeOnMouseLeave={false}
        duration={0}
        animation={"none"}
        autoComplete={"off"}
        error={this.renderError(meta)}
      />
    );
  };

  renderPriceInput = ({ placeholder, type, label, input, meta }) => {
    return (
      <Form.Input
        {...input}
        labelPosition="right"
        type={type}
        label={label}
        placeholder={placeholder}
        error={this.renderError(meta)}
      >
        <Label basic>$</Label>
        <input value={input.value} />
        <Label>.00</Label>
      </Form.Input>
    );
  };

  renderInput = ({
    placeholder,
    label,
    type,
    input,
    meta,
    disabled,
    width,
  }) => {
    console.log("REDNERED", input);
    return (
      <Form.Input
        {...input}
        fluid
        width={width}
        error={this.renderError(meta)}
        label={label}
        placeholder={placeholder}
        value={input.value}
        disabled={disabled}
      />
    );
  };

  renderSelect = ({
    placeholder,
    label,
    type,
    input,
    meta,
    disabled,
    width,
  }) => {
    const tenants = this.props.tenants;
    let options = [];
    tenants.forEach((item, i) => {
      return options.push({
        text: `${item.unique_number}-${item.first_name} ${item.last_name}`,
        value: item.id,
      });
    });

    return (
      <Select
        {...input}
        fluid
        width={width}
        disabled={disabled}
        key={tenants.id}
        label={label}
        placeholder={placeholder}
        options={options}
        onChange={(e, v) => input.onChange(v.value)}
        error={this.renderError(meta)}
      />
    );
  };

  renderTenantFields = ({ fields, meta }) => (
    <>
      <Card.Content>
        <Card.Header>
          Add your own Tenants
          <Popup
            content="You can add Tenants who do not have a RentEZ account."
            trigger={<Icon tiny name="question circle outline" />}
          />
        </Card.Header>
        <Divider />
        {fields.map(this.renderUnregisteredTenantFields)}
      </Card.Content>
      <Card.Content extra>
        <Button
          icon="add"
          label="Add your own Tenant"
          onClick={() => fields.push({})}
        />
      </Card.Content>
    </>
  );

  renderSelectTenantFields = ({ fields, meta }) => (
    <>
      <Card.Content>
        <Card.Header>
          Add a RentEZ Tenant
          <Popup
            content="You can add a Tenant who already has a RentEZ account. Tenants who has a RentEZ account can submit maintenance tickets and their rental history will be recorded."
            trigger={<Icon tiny name="question circle outline" />}
          />
        </Card.Header>
        <Divider />
        {fields.map(this.renderRegisteredTenantFields)}
      </Card.Content>
      <Card.Content extra>
        <Button
          icon="add"
          label="Select a Tenant who already has a RentEZ account"
          onClick={() => fields.push({})}
        />
      </Card.Content>
    </>
  );

  renderRegisteredTenantFields = (tenant, index, fields) => (
    <>
      <Form.Group>
        <Field
          width={8}
          name={`${tenant}.profile_id`}
          component={this.renderSelect}
          placeholder="Select Tenant"
          type="text"
        />
        <Button
          type="button"
          icon="trash"
          compact
          onClick={() => fields.remove(index)}
        />
      </Form.Group>
    </>
  );

  renderUnregisteredTenantFields = (tenant, index, fields) => (
    <>
      <Form.Group>
        <Field
          width={8}
          name={`${tenant}.first_name`}
          component={this.renderInput}
          placeholder="First Name"
          type="text"
        />
        <Field
          width={8}
          name={`${tenant}.last_name`}
          component={this.renderInput}
          placeholder="Last Nmae"
          type="text"
        />
        <Button compact icon="trash" onClick={() => fields.remove(index)} />
      </Form.Group>
    </>
  );

  nextPage = () => this.setState({ page: this.state.page + 1 });

  previousPage = () => this.setState({ page: this.state.page - 1 });

  submitNewLease = async (formData) => {
    const { unit_id, property_id } = this.props.match.params;
    formData.unit_id = unit_id;

    return this.props
      .createNewLease(formData)
      .then(() =>
        this.props.history.push(
          `/landlord/properties/${property_id}/units/${unit_id}`
        )
      )
      .catch((error) => {
        this.previousPage();
        this.props.clearSubmitErrors();
        console.log("ERRORS", new SubmissionError());
        throw new SubmissionError(error);
      });
  };

  toggleRegistered = (e) => {
    this.setState({ registered: !this.state.registered });
  };
  toggleUnregistered = (e) => {
    this.setState({ unregistered: !this.state.unregistered });
  };

  render() {
    const { page } = this.state;
    return (
      <>
        <Header>
          {Object.keys(this.props.selectedUnit).length !== 0 ? (
            <Header.Content>
              <Header.Subheader
                content={this.props.selectedUnit.property.address}
              />
              Unit# {this.props.selectedUnit.unit_num}
            </Header.Content>
          ) : (
            <Loader active inline />
          )}
          <Header.Subheader>Create a new Lease for this unit</Header.Subheader>
        </Header>

        <Form onSubmit={this.props.handleSubmit(this.submitNewLease)}>
          {page === 1 && (
            <>
              <Form.Group widths="equal">
                <Field
                  name="start_date"
                  component={this.renderDatePicker}
                  label="Start Date"
                  placeholder="Date"
                />
                <Field
                  name="end_date"
                  component={this.renderDatePicker}
                  label="End Date"
                  placeholder="Date"
                />
              </Form.Group>

              <Form.Group widths="equal">
                <Field
                  name="rent"
                  component={this.renderPriceInput}
                  placeholder="2,000"
                  label="Rent Price"
                  type="number"
                />
                <Field
                  name="security_deposit"
                  component={this.renderPriceInput}
                  placeholder="2,000"
                  label="Security Deposit"
                  type="number"
                />
                <Field
                  name="last_month_deposit"
                  component={this.renderPriceInput}
                  placeholder="2,000"
                  label="Last Month Deposit"
                  type="number"
                />
              </Form.Group>

              <Divider />
              <Header as="h3" content="Tenants:" />

              <Card fluid>
                <FieldArray
                  name="tenant_profiles_attributes"
                  component={this.renderTenantFields}
                />
              </Card>
              <Card fluid>
                <FieldArray
                  name="tenant_profiles_ids"
                  component={this.renderSelectTenantFields}
                />
              </Card>

              <Button
                style={{ marginTop: "2rem", float: "right" }}
                disabled={this.props.invalid ? true : false}
                onClick={() => this.nextPage()}
                content="Next"
                icon="right arrow"
                labelPosition="right"
              />
            </>
          )}

          {page === 2 && (
            <FormConfirmation
              previousPage={this.previousPage}
              formName="NewLeaseForm"
              submitButtonName="Create Lease"
              formHeader="New Lease Info"
              formObj={this.props.formObj}
              loading={this.props.loading}
            />
          )}
        </Form>
      </>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  const startDate = new Date(formValues.start_date).getTime();
  const endDate = new Date(formValues.end_date).getTime();

  if (!formValues.start_date) {
    errors.start_date = "A start date is required for the Lease";
  }

  if (!formValues.end_date) {
    errors.end_date = "An end date is required for the Lease";
  }

  if (startDate === endDate) {
    errors.start_date = "Start date and end date cannot be the same";
    errors.end_date = "Start date and end date cannot be the same";
  }

  if (startDate > endDate) {
    errors.start_date = "Start date must be before end date";
    errors.end_date = "End date must be after start date";
  }
  if (!formValues.rent) {
    errors.rent = "A rent price is required for the Lease";
  }
  // if (!formsValues.tenant_profile_id) {
  //   errors.tenant_profile_id = "You must add a tenant to begin";
  // }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    selectedUnit: state.units.selectedUnit,
    formObj: state.form.NewLeaseForm,
    tenants: Object.values(state.tenants.tenants),
    loading: state.leases.isLoading,
  };
};

// const submitSuccessCallback = (result, dispatch) => {
//   return dispatch(destroy("NewLeaseForm"));
// };

const wrappedForm = reduxForm({
  form: "NewLeaseForm",
  destroyOnUnmount: false,
  validate,
  // onSubmitSuccess: submitSuccessCallback,
})(NewLeaseForm);

const connectedForm = connect(mapStateToProps, {
  getUnitDetails,
  createNewLease,
  getAllTenants,
})(wrappedForm);

export default withRouter(connectedForm);
