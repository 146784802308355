import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  completeMaintenanceRequest,
  getTenantLeaseMaintenanceRequests,
} from "../../actions";

import { Header, Message } from "semantic-ui-react";

import MaintenanceView from "../../Components/unitDetails/MaintenanceRequests";

const MaintenanceTickets = () => {
  const maintenanceTickets = useSelector((state) => state.maintenanceRequests);
  const activeLease = useSelector((state) => state.leases.lease);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(activeLease).length) {
      dispatch(getTenantLeaseMaintenanceRequests(activeLease.id));
    }
  }, [dispatch]);

  const handleCloseMaintenanceAction = (ticket) =>
    dispatch(completeMaintenanceRequest(ticket));

  const renderedRequests = maintenanceTickets.activeRequestsForCurrentLease ? (
    <MaintenanceView
      tickets={maintenanceTickets}
      handleCloseMaintenanceAction={handleCloseMaintenanceAction}
      activeLease={activeLease}
    />
  ) : (
    <Message
      icon="ban"
      content="You can view and create maintenance requests here once a Lease has been added."
    />
  );

  return (
    <>
      <Header content="Maintenance Requests" />
      {renderedRequests}
    </>
  );
};

export default MaintenanceTickets;
