import React, { Component } from "react";
import { reduxForm, Field, destroy, SubmissionError } from "redux-form";
import { connect } from "react-redux";

import { createNewMaintenance } from "../../actions";

import {
  Form,
  Button,
  Header,
  Divider,
  Segment,
  Popup,
  Icon,
} from "semantic-ui-react";

import FormConfirmation from "./FormConfirmation";

//No hooks for redux-forms; must use HOC

class NewMaintenanceForm extends Component {
  state = { page: 1 };

  renderError = ({ error, touched }) => {
    if (error && touched) {
      return {
        content: error,
        pointing: "above",
      };
    }
  };

  renderTextArea = ({ placeholder, label, type, input, meta }) => {
    return (
      <Form.TextArea
        {...input}
        error={this.renderError(meta)}
        label={label}
        placeholder={placeholder}
        value={input.value}
      />
    );
  };

  renderInput = ({ placeholder, label, type, input, meta }) => {
    return (
      <Form.Input
        {...input}
        error={this.renderError(meta)}
        label={label}
        placeholder={placeholder}
        value={input.value}
      />
    );
  };

  renderCheckBox = ({ label, type, placeholder, input }) => {
    return (
      <Form.Checkbox {...input} label={label} defaultChecked={input.value} />
    );
  };

  nextPage = () => this.setState({ page: this.state.page + 1 });

  previousPage = () => this.setState({ page: this.state.page - 1 });

  submitNewMaintenance = (formData) => {
    formData["lease_id"] = this.props.leaseId;

    return this.props
      .createNewMaintenance(formData)
      .then(() => this.props.closeModal())
      .catch(() => {
        this.props.previousPage();
        throw new SubmissionError();
      });
  };

  render() {
    const { page } = this.state;

    return (
      <>
        <Header>
          New Maintenance Request
          <Header.Subheader>Submit a new maintenance request.</Header.Subheader>
        </Header>
        <Divider hidden />
        <Form onSubmit={this.props.handleSubmit(this.submitNewMaintenance)}>
          {page === 1 && (
            <>
              <Field
                name="title"
                component={this.renderInput}
                placeholder="Title"
                label="Title"
                type="text"
              />
              <Field
                name="description"
                component={this.renderTextArea}
                placeholder="Please describe the maintenace requested"
                label="Description"
                type="text"
              />
              <Divider />
              <Form.Group widths={1}>
                <Field
                  name="urgent"
                  component={this.renderCheckBox}
                  label="Urgent Request"
                  id="urgent"
                />
                <Popup
                  trigger={<Icon name="attention" />}
                  content={
                    "Urgent requests are ones that require immediate attention such as: water leakage, heating malfuntion, and drainage issues"
                  }
                />
              </Form.Group>

              <Form.Group>
                <Button
                  style={{ marginTop: "1rem" }}
                  disabled={this.props.invalid ? true : false}
                  onClick={() => this.nextPage()}
                  content="Next"
                  icon="right arrow"
                  labelPosition="right"
                />
              </Form.Group>
            </>
          )}

          {page === 2 && (
            <FormConfirmation
              previousPage={this.previousPage}
              formName="NewMaintenanceForm"
              submitButtonName="Send"
              formHeader="New Request"
              formObj={this.props.formObject}
              loading={this.props.loading}
            />
          )}
        </Form>
      </>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.title) {
    errors.title = "Title is required";
  }
  if (!formValues.description) {
    errors.description = "Description is required";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    formObject: state.form.NewMaintenanceForm,
    loading: state.maintenanceRequests.isLoading,
  };
};

const submitSuccessCallback = (result, dispatch) => {
  return dispatch(destroy("NewMaintenanceForm"));
};

const wrappedForm = reduxForm({
  form: "NewMaintenanceForm",
  destroyOnUnmount: false,
  validate,
  onSubmitSuccess: submitSuccessCallback,
})(NewMaintenanceForm);

export default connect(mapStateToProps, { createNewMaintenance })(wrappedForm);
