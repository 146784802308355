import React, { Component } from "react";
import { reduxForm, Field, destroy, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Link, useRouteMatch, withRouter } from "react-router-dom";

import { getSelectedProperty, createNewUnit } from "../../actions";

import { Form, Button, Header, Icon } from "semantic-ui-react";
import { colors } from "../../Styles";

import FormConfirmation from "./FormConfirmation";

//No hooks for redux-forms; must use HOC

class NewUnitForm extends Component {
  state = { page: 1 };

  componentDidMount() {
    this.props.getSelectedProperty(this.props.match.params.property_id);
  }

  renderError = ({ error, touched }) => {
    if (error && touched) {
      return {
        content: error,
        pointing: "above",
      };
    }
  };

  renderInput = ({ placeholder, label, type, input, meta }) => {
    return (
      <Form.Input
        {...input}
        error={this.renderError(meta)}
        label={label}
        placeholder={placeholder}
        value={input.value}
      />
    );
  };

  nextPage = () => {
    this.setState({ page: this.state.page + 1 });
  };

  previousPage = () => this.setState({ page: this.state.page - 1 });

  submitNewUnit = (formData) => {
    const { property_id } = this.props.match.params;
    formData.property_id = property_id;
    return this.props
      .createNewUnit(formData)

      .then(() =>
        this.props.history.push(`/landlord/properties/${property_id}/units`)
      )
      .catch((err) => {
        this.previousPage();
        this.props.clearSubmitErrors();

        throw new SubmissionError({
          unit_num: "This unit has already been added",
        });
      });
  };

  render() {
    const { page } = this.state;
    const { property_id } = this.props.match.params;
    const { address, name } = this.props.selectedProperty;
    return (
      <>
        <Header as="h3" dividing>
          <Link to={`/landlord/properties/${property_id}/units`}>
            <Icon name="arrow left" />
            <Header.Content>{name}</Header.Content>
          </Link>
        </Header>

        <Header>Add A New Unit To</Header>

        <Header>
          {this.props.selectedProperty ? (
            <Header.Content>
              {name}
              <Header.Subheader>{address}</Header.Subheader>
            </Header.Content>
          ) : (
            "New Units"
          )}
        </Header>

        <Form onSubmit={this.props.handleSubmit(this.submitNewUnit)}>
          {page === 1 && (
            <>
              <Field
                name="unit_num"
                component={this.renderInput}
                placeholder="1st Floor"
                label="Unit Number"
                type="text"
              />
              <Field
                name="bed_num"
                component={this.renderInput}
                placeholder="3"
                label="Number of Bedrooms"
                type="number"
              />
              <Field
                name="bath_num"
                component={this.renderInput}
                placeholder="1"
                label="Number of Bathrooms"
                type="number"
              />
              <Field
                name="parking_num"
                component={this.renderInput}
                placeholder="2"
                label="Number of Parking Spots"
                type="number"
              />
              <Button
                style={colors.inactive}
                disabled={this.props.invalid ? true : false}
                onClick={() => this.nextPage()}
                content="Next"
                icon="right arrow"
                labelPosition="right"
              />
            </>
          )}

          {page === 2 && (
            <FormConfirmation
              previousPage={this.previousPage}
              submitButtonName="Save Unit"
              formHeader="New Unit Info"
              loading={this.props.loading}
              formObj={this.props.formObj}
            />
          )}
        </Form>
      </>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.unit_num) {
    errors.unit_num = "Please enter the unit number";
  }
  if (!formValues.bath_num) {
    errors.bath_num = "The number of bathrooms is required";
  }
  if (!formValues.bed_num) {
    errors.bed_num = "The number of bedrooms is required";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    selectedProperty: state.properties.selectedProperty,
    formObj: state.form.newUnitForm,
    loading: state.units.isLoading,
  };
};

const submitSuccessCallback = (result, dispatch) => {
  return dispatch(destroy("newUnitForm"));
};

const wrappedForm = reduxForm({
  form: "newUnitForm",
  destroyOnUnmount: false,
  validate,
  onSubmitSuccess: submitSuccessCallback,
})(NewUnitForm);

const connectedForm = connect(mapStateToProps, {
  getSelectedProperty,
  createNewUnit,
})(wrappedForm);

export default withRouter(connectedForm);
