import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getUnitDetails,
  clearActiveLease,
  clearUnit,
  getLeaseDetails,
  selectUnit,
  setLeasesForUnit,
} from "../../../actions";

import { Switch, Route, Link } from "react-router-dom";

import { Menu, Header, Icon, Divider } from "semantic-ui-react";

import DetailsTab from "./DetailsTab";
import SettingsTab from "./SettingsTab";
import ActiveLeaseTab from "./ActiveLeaseTab";
import MaintenanceTicketsTab from "./MaintenanceTab";
import Loading from "../../../Components/Loading";

const UnitOverView = (props) => {
  const userUnits = useSelector((state) => state.units.userUnits);

  const unitDetails = useSelector((state) => state.units.selectedUnit);

  const { unitLeases } = useSelector((state) => state.leases);

  const loading = useSelector((state) => state.units.isLoading);

  const { url, params } = props.match;
  const { pathname } = props.location;

  const dispatch = useDispatch();

  const [state, setState] = useState({});

  //After container loads, the state is set to current pathname
  useEffect(() => {
    setState({ activeItem: pathname });
  }, [pathname]);

  //FETCH unit details from server everytime to keep data fresh
  useEffect(() => {
    dispatch(getUnitDetails(params.unit_id));

    return () => {
      dispatch(clearActiveLease());
      return dispatch(clearUnit());
    };
  }, [dispatch]);

  const { id, unit_num, property } = unitDetails;
  const { activeItem } = state;

  //If unitDetail object is NOT empty then render else display loading
  return Object.keys(unitDetails).length ? (
    <>
      <Header as="h4" dividing>
        <Link to={`/landlord/properties/${property.id}`}>
          <Icon name="arrow left" />
          <Header.Content>{property.name}</Header.Content>
        </Link>
        <Header.Subheader dividing content={property.address} />
      </Header>

      <Header as="h3">
        <Header.Subheader content="Unit Details" />
        <Header.Content content={`Unit: ${unit_num}`} />
      </Header>

      <Menu pointing secondary>
        <Menu.Item
          name="activeLease"
          as={Link}
          to={`${url}/active-lease`}
          active={activeItem === `${url}/active-lease`}
        />
        <Menu.Item
          name="Maintenance"
          as={Link}
          to={`${url}/maintenance-requests`}
          active={activeItem === `${url}/maintenance-requests`}
        />
        <Menu.Item
          name="details"
          as={Link}
          to={`${url}/details`}
          active={activeItem === `${url}/details`}
        />
        <Menu.Item
          name="Settings"
          position="right"
          active={activeItem === `${url}/settings`}
          as={Link}
          to={`${url}/settings`}
        />
      </Menu>

      <Switch>
        <Route
          path={`/landlord/properties/:property_id/units/:unit_id/details/`}
          component={DetailsTab}
        />
        <Route
          path={`/landlord/properties/:property_id/units/:unit_id/maintenance-requests`}
          component={MaintenanceTicketsTab}
        />
        <Route
          path={`/landlord/properties/:property_id/units/:unit_id/settings`}
          component={SettingsTab}
        />
        <Route
          path={`/landlord/properties/:property_id/units/:unit_id`}
          component={ActiveLeaseTab}
        />
        )} />
      </Switch>
    </>
  ) : (
    <Loading nameOfResource="Active Lease" />
  );
};

export default UnitOverView;
