import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getUnitNotes, removeUnitNote } from "../../../actions";

import {
  Button,
  Accordion,
  Icon,
  Header,
  Divider,
  Card,
  List,
  Message,
} from "semantic-ui-react";

import { Switch, Route, Link } from "react-router-dom";

import InfoView from "../../../Components/unitDetails/InfoTab";
import NoteCard from "../../../Components/NoteCard";
import NewNoteModal from "../../../Components/Modals/NewNotes";

import PastLeases from "./PastLeases";

const DetailsTab = (props) => {
  const [state, setState] = useState({});
  const dispatch = useDispatch();
  const { params, url } = props.match;

  const handleBtnClick = () => {
    setState({ show: !state.show });
  };

  const { activeIndex } = state;
  //Add logic for opening and close accordion
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setState({ activeIndex: newIndex });
  };

  const unitDetails = useSelector((state) => state.units.selectedUnit);

  const deleteNoteAction = (id) => dispatch(removeUnitNote(id));

  //CONSIDER LOADING NOTES FOR UNIT ONLY AFTER USER EXPANDS NOTES SECTION
  useEffect(() => {
    if (!unitDetails.notes) {
      dispatch(getUnitNotes(params.id));
    }
  }, [dispatch]);

  const renderInfo = <InfoView unitDetails={unitDetails} />;

  const renderNotes =
    unitDetails["notes"] && Object.keys(unitDetails.notes).length ? (
      <Card fluid>
        <Card.Content>
          <List divided relaxed>
            {Object.values(unitDetails.notes).map((note) => (
              <NoteCard note={note} deleteNoteAction={deleteNoteAction} />
            ))}
          </List>
        </Card.Content>
      </Card>
    ) : (
      <Message content="There are no notes to display" />
    );

  return (
    <>
      {renderInfo}

      <Accordion>
        <NewNoteModal
          headerTitle="Unit Notes"
          noteParentType="unit_id"
          noteParentId={params.id}
        />
        <Accordion.Title
          as={Button}
          fluid
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
        >
          <Icon name="dropdown" />
          View Notes
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          {renderNotes}
        </Accordion.Content>

        <Header content="Past Leases" />
        <Divider />

        <Accordion.Title
          as={Button}
          fluid
          active={activeIndex === 1}
          index={1}
          onClick={handleClick}
        >
          <Icon name="dropdown" />
          View Past Leases
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <Route render={(props) => <PastLeases {...props} />} />
        </Accordion.Content>
      </Accordion>
    </>
  );
};

export default DetailsTab;
