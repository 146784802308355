import { combineReducers } from "redux";

import auth from "./authReducer";
import app from "./appReducer";
import properties from "./propertiesReducer";
import units from "./unitsReducer";
import leases from "./leaseReducer";
import tenants from "./tenantReducer";
import maintenanceRequests from "./maintenanceReducer";
import popUpMessage from "./popUpMessage";
import leaseRequests from "./leaseRequestReducer";

import { reducer as formReducer } from "redux-form";

export default combineReducers({
  auth,
  app,
  popUpMessage,
  form: formReducer,
  properties,
  units,
  leases,
  tenants,
  maintenanceRequests,
  leaseRequests,
});
