import React, { Component } from "react";
import Moment from "react-moment";

import { reduxForm, Field, SubmissionError } from "redux-form";
import { connect } from "react-redux";

import { Form, Button, Header, Table } from "semantic-ui-react";

//No hooks for redux-forms; must use HOC

class ProfileEditForm extends Component {
  renderError = ({ error, touched }) => {
    if (error && touched) {
      return true;
    }
  };

  renderInput = ({ placeholder, label, type, input, meta, pattern }) => {
    return (
      <Form.Input
        {...input}
        fluid
        error={this.renderError(meta)}
        placeholder={placeholder}
        value={input.value}
        type={type}
      />
    );
  };

  render() {
    const { email, profileType } = this.props;
    const {
      unique_number,
      first_name,
      last_name,
      phone,
      created_at,
      type,
    } = this.props.profileInfo;
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <Table unstackable definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Name</Table.Cell>
              <Table.Cell>
                <Form.Group widths="equal" style={{ margin: 0 }}>
                  <Field
                    name="first_name"
                    component={this.renderInput}
                    type="text"
                    placeholder="First Name"
                  />
                  <Field
                    name="last_name"
                    component={this.renderInput}
                    type="text"
                    placeholder="Last name"
                  />
                </Form.Group>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width="4">Account Type</Table.Cell>
              <Table.Cell>{type}</Table.Cell>
            </Table.Row>
            {unique_number && (
              <Table.Row>
                <Table.Cell>Profile ID#</Table.Cell>
                <Table.Cell>{unique_number}</Table.Cell>
              </Table.Row>
            )}

            <Table.Row>
              <Table.Cell>E-mail</Table.Cell>
              <Table.Cell>{email}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Phone</Table.Cell>
              <Table.Cell>
                <Field
                  name="phone"
                  component={this.renderInput}
                  type="number"
                  placeholder="Phone Number"
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  const { first_name, last_name } = formValues;

  if (!first_name) {
    errors.first_name = "Required";
  }
  if (!last_name) {
    errors.last_name = "Required";
  }

  return errors;
};

const mapStateToProps = (state, ownProps) => {
  const { first_name, last_name, email, phone, id } = ownProps.profileInfo;

  return {
    //onSubmit is to enable remote submit from outside of form component
    onSubmit: ownProps.handleProfileSubmit,
    email: state.auth.email,
    initialValues: { first_name, last_name, phone, id },
    loading: state.app.loading,
  };
};

const wrappedForm = reduxForm({
  form: "ProfileEditForm",
  validate,
})(ProfileEditForm);

export default connect(mapStateToProps)(wrappedForm);
