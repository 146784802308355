import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getPendingLeaseRequests, respondToLeaseRequest } from "../../actions";

import { Header, Message } from "semantic-ui-react";

import LeaseRequestCard from "../../Components/LeaseRequestCards";

const LeaseRequests = () => {
  const leaseRequests = useSelector((state) => state.leaseRequests.requests);

  const updateLeaseRequest = (request) =>
    dispatch(respondToLeaseRequest(request));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPendingLeaseRequests());
  }, [dispatch]);

  const renderedRequests = Object.values(leaseRequests).map((request) => (
    <LeaseRequestCard
      key={request.id}
      request={request}
      updateLeaseRequest={updateLeaseRequest}
    />
  ));

  const renderNoRequestMessage = (
    <Message header="No Requests" content="You have no Lease requests." />
  );

  return (
    <>
      <Header>Pending Lease Requests:</Header>
      {Object.keys(leaseRequests).length
        ? renderedRequests
        : renderNoRequestMessage}
    </>
  );
};

export default LeaseRequests;
