import React, { Component } from "react";
import { reduxForm, Field, destroy, change } from "redux-form";
import { connect } from "react-redux";

import { Form, Button, Header } from "semantic-ui-react";

import AddressField from "../AddressField";

//No hooks for redux-forms; must use HOC

class PropertyNameField extends Component {
  renderError = ({ error, touched }) => {
    if (error && touched) {
      return {
        content: error,
        pointing: "above",
      };
    }
  };
  renderAddressInput = ({ placeholder, label, type, input, meta }) => {
    return (
      <AddressField
        {...input}
        name="address"
        error={this.renderError(meta)}
        placeholder={placeholder}
        value={input.value}
        onChange={(e, v) => input.onChange(e.value.description)}
      />
    );
  };

  renderInput = ({ placeholder, label, type, input, meta }) => {
    return (
      <Form.Input
        {...input}
        error={this.renderError(meta)}
        placeholder={placeholder}
        value={input.value}
      />
    );
  };

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <Field
          name="name"
          component={this.renderInput}
          placeholder="Property Name"
          label="Name"
          type="text"
        />
      </Form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.name) {
    errors.name = "Please enter a name for your property";
  }

  return errors;
};

const mapStateToProps = (state, ownProps) => {
  const { id, name } = ownProps.propertyDetails;
  return {
    onSubmit: ownProps.editPropertyAction,
    initialValues: { id, name },
    fields: state.form.newpropertyForm,
    loading: state.properties.loading,
  };
};

const submitSuccessCallback = (result, dispatch) => {
  return dispatch(destroy("PropertyNameField"));
};

const wrappedForm = reduxForm({
  form: "PropertyNameField",
  destroyOnUnmount: false,
  validate,
  onSubmitSuccess: submitSuccessCallback,
})(PropertyNameField);

export default connect(mapStateToProps)(wrappedForm);
