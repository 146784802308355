import _ from "lodash";

import {
  LOADING_PROPERTY_COMPLETE,
  LOADING_PROPERTY,
  FETCH_USER_PROPERTIES,
  FETCH_ALL_PROPERTIES,
  FETCH_PROPERTY_DETAILS,
  POST_NEW_PROPERTY,
  ADD_PROPERTY_NOTE,
  DELETE_PROPERTY,
  DELETE_UNIT,
  EDIT_PROPERTY,
  USER_SIGN_OUT,
} from "../actions/types";

const INITIAL_STATE = {
  isLoading: false,
  allProperties: {},
  userProperties: {},
  selectedProperty: {
    notes: {},
  },
  // propertyUnits: {},
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_PROPERTY:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_PROPERTY_COMPLETE:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_USER_PROPERTIES:
      return {
        ...state,
        userProperties: { ..._.mapKeys(action.payload, "id") },
      };

    case FETCH_ALL_PROPERTIES:
      return {
        ...state,
        allProperties: { ..._.mapKeys(action.payload, "id") },
      };

    case FETCH_PROPERTY_DETAILS:
      return {
        ...state,
        selectedProperty: {
          ...action.payload,
        },
      };

    case EDIT_PROPERTY:
      return {
        ...state,
        selectedProperty: action.payload,
      };

    case ADD_PROPERTY_NOTE:
      return {
        ...state,
        selectedProperty: {
          ...state.selectedProperty,
          notes: {
            ...state.selectedProperty.notes,
            [action.payload.id]: action.payload,
          },
        },
      };

    case POST_NEW_PROPERTY:
      return {
        ...state,
        userProperties: {
          ...state.userProperties,
          [action.payload.id]: action.payload,
        },
      };

    case DELETE_PROPERTY:
      return {
        ...state,
        userProperties: {
          ..._.omit(state.userProperties, action.payload),
        },
      };

    // case DELETE_UNIT:
    //   return {
    //     ...state,
    //     selectedProperty: {
    //       ...state.selectedProperty,
    //       units: state.selectedProperty.units.filter(
    //         (unit) => unit.id !== action.payload
    //       ),
    //     },
    //   };

    case USER_SIGN_OUT:
      return {
        ...state,
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};
