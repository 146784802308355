import _ from "lodash";
import {
  UPDATE_LEASE,
  FETCH_LEASE,
  FETCH_ACTIVE_LEASES,
  FETCH_SELECTED_UNIT_ACTIVE_LEASE,
  FETCH_SELECTED_UNIT_LEASES,
  FETCH_LEASE_DETAILS,
  FETCH_USER_LEASES,
  LOADING_LEASES,
  LOADING_LEASE,
  LOADING_LEASE_COMPLETE,
  POST_LEASE,
  FETCH_LEASE_NOTES,
  ADD_LEASE_NOTE,
  DELETE_LEASE_NOTE,
  CANCEL_LEASE,
  MAKE_ACTIVE,
  LOADING_LEASES_COMPLETE,
  FETCH_TENANT_ACTIVE_LEASE,
  SET_SELECTED_UNIT_ACTIVE_LEASE,
  FETCH_TENANT_LEASES,
  CLOSE_MAINTENANCE,
  CLEAR_ACTIVE_LEASE,
  SET_UNIT_LEASES,
  EDIT_LEASE_TENANT_PROFILE,
  DELETE_LEASE_REQUEST,
  DELETE_TENANT,
  USER_SIGN_OUT,

  // COMPLETE_MAINTENANCE_REQUEST,
} from "../actions/types";

const INITIAL_STATE = {
  userLeases: {},
  unitLeases: {},
  lease: {},
  isLoading: false,
  activeLeases: {},
  selectedLease: {},
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_LEASE:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_LEASE_COMPLETE:
      return {
        ...state,
        isLoading: false,
      };

    case CLEAR_ACTIVE_LEASE:
      return {
        ...state,
        lease: {},
      };

    case POST_LEASE:
      return {
        ...state,
      };

    case FETCH_USER_LEASES:
      return {
        ...state,
        userLeases: { ..._.mapKeys(action.payload, "id") },
      };

    case FETCH_LEASE_DETAILS:
      return {
        ...state,
        lease: action.payload,
      };

    case FETCH_ACTIVE_LEASES:
      return {
        ...state,
        lease: { ..._.mapKeys(action.payload, "id") },
      };

    case FETCH_SELECTED_UNIT_LEASES:
      return {
        ...state,
        unitLeases: { ..._.mapKeys(action.payload, "id") },
      };

    case FETCH_SELECTED_UNIT_ACTIVE_LEASE:
      return {
        ...state,
        activeLease: action.payload,
        //display lease in component
      };

    case CANCEL_LEASE:
      return {
        ...state,
        lease: {},
      };

    case FETCH_LEASE_NOTES:
      return {
        ...state,
        lease: {
          ...state.lease,
          notes: { ..._.mapKeys(action.payload, "id") },
        },
      };

    case ADD_LEASE_NOTE:
      // const newNotes = state.lease.notes.concat(action.payload);

      return {
        ...state,
        lease: {
          ...state.lease,
          notes: {
            ...state.lease.notes,
            [action.payload.id]: action.payload,
          },
        },
      };

    case DELETE_LEASE_NOTE:
      // const updatedNotes = state.activeLease.notes.filter(
      //   (note) => note.id !== action.payload
      // );

      return {
        ...state,
        lease: {
          ...state.lease,
          notes: {
            ..._.omit(state.lease.notes, action.payload),
          },
        },
      };

    case EDIT_LEASE_TENANT_PROFILE:
      const arrayWithout = state.lease.tenant_profiles.filter(
        (tenant) => tenant.id !== action.payload.id
      );

      const updatedArray = arrayWithout.concat(action.payload);

      return {
        ...state,
        lease: {
          ...state.lease,
          tenant_profiles: updatedArray,
        },
      };

    case DELETE_TENANT:
      const arrayWithoutTenant = state.lease.tenant_profiles.filter(
        (tenant) => tenant.id !== action.payload
      );

      return {
        ...state,
        lease: {
          ...state.lease,
          tenant_profiles: arrayWithoutTenant,
        },
      };

    case DELETE_LEASE_REQUEST:
      const arrayWithoutSelected = state.lease.lease_requests.filter(
        (request) => request.id !== action.payload
      );

      return {
        ...state,
        lease: {
          ...state.lease,
          lease_requests: arrayWithoutSelected,
        },
      };

    case FETCH_LEASE:
      return {
        ...state,
        lease: { ...action.payload },
      };

    case UPDATE_LEASE:
      return {
        ...state,
        lease: {
          ...action.payload,
        },
      };
    case FETCH_TENANT_ACTIVE_LEASE:
      const activeLease = action.payload[0];
      return {
        ...state,
        lease: { ...activeLease },
      };

    case FETCH_TENANT_LEASES:
      return {
        ...state,
        userLeases: { ...action.payload },
      };

    case USER_SIGN_OUT:
      return {
        ...state,
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};
