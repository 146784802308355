import React from "react";
import { Header, Message } from "semantic-ui-react";

import { colors } from "../../Styles";

import MaintenanceCard from "../MaintenanceRequestCard";

const MaintenanceRequests = ({
  tickets,
  handleCloseMaintenanceAction,
  activeLease,
}) => {
  let active = Object.values(tickets.activeRequestsForCurrentLease);
  let completed = Object.values(tickets.completedRequestsForCurrentLease);

  const sorted = (list) => {
    return list.sort((current, next) => {
      return current.created_at > next.created_at
        ? -1
        : current.created_at < next.created_at
        ? 1
        : 0;
    });
  };

  const activeTix =
    active.length === 0 ? (
      <Message
        style={colors.inactive}
        icon="thumbs up"
        header="Congrats!"
        content="There are no open maintenance requests for you to review. Kick back and relax."
      />
    ) : (
      active.map((tix) => (
        <MaintenanceCard
          tix={tix}
          completeAction={handleCloseMaintenanceAction}
        />
      ))
    );

  const completedTix =
    completed.length === 0 ? (
      ""
    ) : (
      <>
        <Header>Completed Tickets</Header>
        {sorted(completed).map((tix) => (
          <MaintenanceCard
            key={tix.id}
            tix={tix}
            completeAction={handleCloseMaintenanceAction}
          />
        ))}
      </>
    );

  return (
    <>
      <Header>Active Tickets</Header>
      {activeTix}
      {completedTix}
    </>
  );
};

export default MaintenanceRequests;
