import React from 'react';
import { Message, Icon } from 'semantic-ui-react';

const Loading = ({ nameOfResource, message }) => (
  <Message icon>
    <Icon name='circle notched' loading />
    <Message.Content>
      <Message.Header>Loading {nameOfResource}...</Message.Header>
      We are fetching that content for you.
    </Message.Content>
  </Message>
);

export default Loading;
