import React from 'react';
import { useSelector } from 'react-redux';

import { Route, Redirect } from 'react-router-dom';

function TenantAuth({ component: Component, ...rest}){

  const auth = useSelector(state => state.auth.isSignedIn)
  const isLandlord = useSelector(state => state.auth.landlord_profile)
  const isTenant = useSelector(state => state.auth.tenant_profile)

  //If signin in already, go to the Component
  //If no signed in, redirect to the sign in page with data of where user is being redirected from saved to Redirect state prop. After user signs in inside signin form, use the redirect state prop to push the user back to where they came from
  //
  // <Component {...routeProps}/> :
  // <Redirect
  //   to={{
  //     pathname: "/signIn",
  //     state: {from: routeProps.location}
  //   }} />

  return (
    <Route
      {...rest}
      render={ routeProps => {
        if (!auth) {
          return <Redirect
            to={{
              pathname: '/signIn',
              state: {from: routeProps.location}
            }} />
        } else if (!isTenant) {
          return <Redirect
            to={{
              pathname: '/landlord/properties',
              state: {from: routeProps.location}
            }} />
        } else {
          return <Component {...routeProps}/>
        }

      }}/>
  )
}

export default TenantAuth;
