import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Item, Header, Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { submit } from "redux-form";

import { editUnit } from "../../actions";

import PastLeases from "./PastLeases";
import EditUnitInfoFields from "../../Containers/Forms/Fields/EditUnitInfo";

const InfoTab = ({ unitDetails }) => {
  const { id, bed_num, bath_num, parking_num } = unitDetails;
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  const displayEditLink = (
    <Link onClick={() => setEdit(true)}>
      <Icon name="edit" />
      Edit
    </Link>
  );

  const handleEditSubmit = (form) => {
    dispatch(editUnit(form));
    setEdit(false);
  };

  const displayEditActions = (
    <>
      <Button
        content="Save"
        onClick={() => dispatch(submit("UnitInfoFields"))}
      />
      <Button content="Cancel" onClick={() => setEdit(false)} />
    </>
  );

  const detailList = (
    <Item.Group divided>
      <Item>
        <Item.Content>Bedrooms: {bed_num}</Item.Content>
        <Item.Content>Bathrooms: {bath_num}</Item.Content>
        <Item.Content>Parking Spaces: {parking_num}</Item.Content>
      </Item>
    </Item.Group>
  );

  const editFields = (
    <EditUnitInfoFields
      unitDetails={unitDetails}
      handleEditSubmit={handleEditSubmit}
    />
  );

  return (
    <>
      <Header>Unit Details</Header>
      <Card className="ui fluid">
        <Card.Content>
          <span style={{ float: "right" }}>
            {edit ? displayEditActions : displayEditLink}
          </span>
          Unit Info
        </Card.Content>
        <Card.Content>{edit ? editFields : detailList}</Card.Content>
      </Card>
    </>
  );
};

export default InfoTab;
