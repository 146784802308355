import {
  USER_SIGN_IN,
  USER_SIGN_OUT,
  USER_SIGN_UP,
  EDIT_LANDLORD_PROFILE,
  EDIT_TENANT_PROFILE,
  LOADING,
  RESET_POP_UP,
  REQUEST_FAILED,
  SUCCESS_POPUP,
} from "../actions/types";

const INITIAL_STATE = {
  isOpened: false,
  message: null,
  requestFailed: false,
  response: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUCCESS_POPUP:
      return {
        ...state,
        isOpened: true,
        message: action.payload,
      };
    case REQUEST_FAILED:
      return {
        ...state,
        requestFailed: true,
        response: { ...action.payload },
      };
    case RESET_POP_UP:
      return {
        ...state,
        isOpened: false,
        message: null,
      };

    //
    // case EDIT_TENANT_PROFILE:
    //   return {
    //     ...state,
    //     loading: false,
    //     tenant_profile: action.payload,
    //   };
    //
    // case EDIT_LANDLORD_PROFILE:
    //   return {
    //     ...state,
    //     loading: false,
    //     landlord_profile: action.payload,
    //   };

    default:
      return state;
  }
};
