import React from "react";
import Moment from "react-moment";
import { Table, Message, Header } from "semantic-ui-react";

const ProfileView = ({ profileInfo, email }) => {
  const {
    unique_number,
    first_name,
    last_name,
    phone,
    created_at,
    pending,
    type,
  } = profileInfo;

  const renderPendingStatus = (
    <Message>
      <Header>Request Pending...</Header>A request has already been sent to the
      Tenant.
    </Message>
  );

  return (
    <>
      <Table unstackable definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell width="4">Name</Table.Cell>
            <Table.Cell>
              {first_name} {last_name}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Account Type</Table.Cell>
            <Table.Cell>{type}</Table.Cell>
          </Table.Row>
          {unique_number && (
            <Table.Row>
              <Table.Cell>Profile ID#</Table.Cell>
              <Table.Cell>{unique_number}</Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>E-mail</Table.Cell>
            <Table.Cell>{email}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Phone</Table.Cell>
            <Table.Cell>{phone}</Table.Cell>
          </Table.Row>
          {unique_number && (
            <Table.Row>
              <Table.Cell>Member since</Table.Cell>
              <Table.Cell>
                <Moment format="MMMM DD, YYYY" date={created_at} />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {pending && renderPendingStatus}
    </>
  );
};

export default ProfileView;
