import React from 'react';
import { useSelector } from 'react-redux';

import { Route, Redirect } from 'react-router-dom';

function LandlordAuth({ component: Component, ...rest}){

  const auth = useSelector(state => state.auth.isSignedIn)
  const isLandlord = useSelector(state => state.auth.landlord_profile)
  const isTenant = useSelector(state => state.auth.tenant_profile)

  //If signin in already AND user is a Landlord then go to component
  //If not signed in, redirect to the sign in page with data of where user is being redirected from saved to Redirect state prop. After user signs in inside signin form, use the redirect state prop to push the user back to where they came from
  //
  // <Component {...routeProps}/> :
  // <Redirect
  //   to={{
  //     pathname: "/signIn",
  //     state: {from: routeProps.location}
  //   }} />

  return (
    <Route
      {...rest}
      render={ routeProps => {
        if (!auth) {
          return <Redirect
            to={{
              pathname: '/signIn',
              state: {from: routeProps.location}
            }} />
        } else if (!isLandlord) {
          return <Redirect
            to={{
              pathname: '/tenant/active-lease',
              state: {from: routeProps.location}
            }} />
        } else {
          return <Component {...routeProps}/>
        }

      }}/>
  )
}

export default LandlordAuth;
