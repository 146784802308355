import React, { Component } from "react";
import {
  reduxForm,
  Field,
  destroy,
  SubmissionError,
  FieldArray,
} from "redux-form";
import { connect } from "react-redux";

import { addTenantToLease, getAllTenants } from "../../actions";

import {
  Form,
  Button,
  Header,
  Divider,
  Select,
  Modal,
  Grid,
} from "semantic-ui-react";

import FormConfirmation from "./FormConfirmation";

//No hooks for redux-forms; must use HOC

class AddTenantForm extends Component {
  state = { page: 1 };

  registeredTenantArrayMethods;
  unregisteredTenantArrayMethods;

  componentWillMount() {
    this.props.getAllTenants();
  }

  componentWillUnmount() {
    this.props.dispatch(destroy("AddTenantForm"));
  }

  // renderError = ({ error, touched }) => {
  //   if (error && touched) {
  //     return;
  //   }
  // };

  renderInput = ({
    placeholder,
    label,
    type,
    input,
    meta,
    disabled,
    width,
  }) => {
    return (
      <Form.Input
        {...input}
        fluid
        width={width}
        error={meta.error && meta.touched}
        label={label}
        placeholder={placeholder}
        value={input.value}
      />
    );
  };
  renderSelect = ({ placeholder, label, type, input, meta, disabled }) => {
    const tenants = this.props.tenants;
    let options = [];
    tenants.forEach((item, i) => {
      return options.push({
        text: `${item.unique_number}-${item.first_name} ${item.last_name}`,
        value: item.id,
      });
    });

    return (
      <Select
        {...input}
        style={{ marginBottom: "0.5rem" }}
        fluid
        key={tenants.id}
        label={label}
        placeholder={placeholder}
        options={options}
        onChange={(e, v) => input.onChange(v.value)}
      />
    );
  };

  renderSearchTenants = (arrayMethods) => {
    this.bindRegistedTenantArrayMethods(arrayMethods);

    return arrayMethods.fields.map((tenant, index, fields) => (
      <Grid columns="equal">
        <Grid.Column key={`${tenant}.${index}`}>
          <Field
            name={`${tenant}.profile_id`}
            component={this.renderSelect}
            placeholder="Tenant Name"
            label="Tenant"
            type="text"
          />
        </Grid.Column>

        <Grid.Column width={2}>
          <Button
            className="negative"
            type="button"
            icon="trash"
            compact
            onClick={() => fields.remove(index)}
          />
        </Grid.Column>
      </Grid>
    ));
  };

  renderAddTenant = (arrayMethods) => {
    const { meta } = arrayMethods;
    this.bindUnregistedTenantArrayMethods(arrayMethods);

    return (
      <>
        {arrayMethods.fields.map((tenant, index, fields) => (
          <Grid columns="equal">
            <Grid.Column key={`${tenant}.${index}`}>
              <Form.Group widths="equal">
                <Field
                  name={`${tenant}.first_name`}
                  component={this.renderInput}
                  placeholder="First Name"
                  type="text"
                />
                <Field
                  name={`${tenant}.last_name`}
                  component={this.renderInput}
                  placeholder="Last Name"
                  type="text"
                />
              </Form.Group>
            </Grid.Column>

            <Grid.Column width={2}>
              <Button
                className="negative"
                type="button"
                icon="trash"
                compact
                onClick={() => fields.remove(index)}
              />
            </Grid.Column>
          </Grid>
        ))}
      </>
    );
  };

  nextPage = () => this.setState({ page: this.state.page + 1 });

  previousPage = () => this.setState({ page: this.state.page - 1 });

  submitAddition = (formData) => {
    const updatedLease = { ...formData, id: this.props.lease.id };
    return (
      this.props
        .addTenantToLease(updatedLease)
        // .then(() => this.props.closeModal())
        .catch((error) => {
          console.log("SUBMISSION ERROR", error);
          throw new SubmissionError({
            _error: "Tenant has already been added",
          });
        })
    );
  };

  cancel = () => {
    this.props.closeModal();
    this.props.dispatch(destroy("AddTenantForm"));
  };

  bindRegistedTenantArrayMethods = (methods) => {
    return (this.registeredTenantArrayMethods = methods);
  };

  bindUnregistedTenantArrayMethods = (methods) => {
    return (this.unregisteredTenantArrayMethods = methods);
  };

  render() {
    const { page } = this.state;
    return (
      <>
        <Modal.Content>
          <Form onSubmit={this.props.handleSubmit(this.submitAddition)}>
            {page === 1 && (
              <>
                <FieldArray
                  name="tenant_profiles_attributes"
                  component={this.renderAddTenant}
                />
                <FieldArray
                  name="tenant_profiles_ids"
                  rerenderOnEveryChange={false}
                  component={this.renderSearchTenants}
                />

                <Button.Group
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    content="Add Custom Tenant"
                    onClick={(e) => {
                      e.preventDefault();

                      this.unregisteredTenantArrayMethods.fields.push({});
                    }}
                  />
                  <Button.Or />
                  <Button
                    content="Add Registered Tenant"
                    onClick={(e) => {
                      e.preventDefault();
                      this.registeredTenantArrayMethods.fields.push({});
                    }}
                  />
                </Button.Group>
              </>
            )}

            {page === 2 && (
              <FormConfirmation
                previousPage={this.previousPage}
                formName="AddTenantForm"
                submitButtonName="Update Lease"
                formHeader="New Tenant"
                formObj={this.props.formObj}
              />
            )}
          </Form>
        </Modal.Content>

        <Modal.Actions style={{ textAlign: "left" }}>
          <Button
            onClick={() => this.cancel()}
            content="Cancel"
            icon="cancel"
            labelPosition="left"
          />
          {page === 1 && (
            <Button
              disabled={this.props.invalid}
              onClick={() => this.nextPage()}
              content="Next"
              floated="right"
              icon="right arrow"
              labelPosition="right"
            />
          )}
        </Modal.Actions>
      </>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  const { tenant_profiles_attributes, tenant_profiles_ids } = formValues;

  //if tenant profile attributes is empty then invalid
  if (!tenant_profiles_attributes || !tenant_profiles_attributes.length) {
    if (!tenant_profiles_ids || !tenant_profiles_ids.length) {
      errors.tenant_profiles_ids = { _error: "Tenant is required" };
      errors.tenant_profiles_attributes = { _error: "Tenant is required" };
    }
  } else {
    const tenantArrayErrors = [];
    tenant_profiles_attributes.forEach((tenant, tenantIndex) => {
      const tenantErrors = {};
      if (!tenant || !tenant.first_name) {
        tenantErrors.first_name = "Required";
        tenantArrayErrors[tenantIndex] = tenantErrors;
      }
      if (!tenant || !tenant.last_name) {
        tenantErrors.last_name = "Required";
        tenantArrayErrors[tenantIndex] = tenantErrors;
      }
    });
    if (tenantArrayErrors.length) {
      errors.tenant_profiles_attributes = tenantArrayErrors;
    }
  }
  //If tenant profiles id is empty then invalid
  if (!tenant_profiles_ids || !tenant_profiles_ids.length) {
    if (!tenant_profiles_attributes || !tenant_profiles_attributes.length) {
      errors.tenant_profiles_ids = { _error: "Tenant is required" };
      errors.tenant_profiles_attributes = { _error: "Tenant is required" };
    }
  } else {
    const tenantIdArrayErrors = [];

    tenant_profiles_ids.forEach((tenant, tenantIndex) => {
      const tenantErrors = {};
      if (!tenant || !tenant.profile_id) {
        tenantErrors.profile_id = "Required";
        tenantIdArrayErrors[tenantIndex] = tenantErrors;
      }
    });

    if (tenantIdArrayErrors.length) {
      errors.tenant_profiles_ids = tenantIdArrayErrors;
    }
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    formObj: state.form.AddTenantForm,
    tenants: Object.values(state.tenants.tenants),
    // lease: state.leases.selectedUnit.activeLease
    // loading: state.properties.loading
  };
};

const submitSuccessCallback = (result, dispatch) => {
  console.log("S CB", result);
  return dispatch(destroy("AddTenantForm"));
};

const wrappedForm = reduxForm({
  form: "AddTenantForm",
  destroyOnUnmount: false,
  validate,
  onSubmitSuccess: submitSuccessCallback,
})(AddTenantForm);

export default connect(mapStateToProps, { addTenantToLease, getAllTenants })(
  wrappedForm
);
