import {
  api,
  fetchUserProperties,
  fetchSelectedProperty,
  updateProperty,
  postNewProperty,
  deleteProperty,
  fetchUserUnits,
  fetchUnitDetails,
  postNewUnit,
  updateUnit,
  deleteUnit,
  fetchLeaseDetails,
  postNewLease,
  updateLease,
  fetchUnitLeases,
  cancelActiveLease,
  createActiveLease,
  postNewMaintenance,
  updateMaintenanceRequest,
  fetchMaintenanceForLease,
  fetchAllActiveMaintenanceRequests,
  fetchUnitNotes,
  postNewNote,
  deleteNote,
  fetchAllTenants,
  fetchTenant,
  deleteTenant,
  updateTenantProfile,
  deleteLeaseRequest,
} from "./api";

import {
  successPopUp,
  failurePopUp,
  requestSuccess,
  requestFailed,
} from "./index";

import {
  LOADING,
  LOADING_UNIT,
  LOADING_LEASE,
  LOADING_LEASE_COMPLETE,
  LOADING_UNIT_COMPLETE,
  LOADING_PROPERTY_COMPLETE,
  LOADING_MAINTENANCE_REQUEST_COMPLETE,
  LOADING_NOTES_COMPLETE,
  LOADING_LEASE_REQUEST_COMPLETE,
  LOADING_TENANT_COMPLETE,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
  DISPLAY_POP_UP,
  SUCCESS_POPUP,

  //PROPERTY
  FETCH_USER_PROPERTIES,
  FETCH_PROPERTY_DETAILS,
  DELETE_PROPERTY,
  ADD_PROPERTY_NOTE,
  DELETE_PROPERTY_NOTE,
  EDIT_PROPERTY,
  POST_NEW_PROPERTY,

  //UNIT
  FETCH_USER_UNITS,
  FETCH_UNIT_DETAILS,
  POST_NEW_UNIT,
  DELETE_UNIT,
  ADD_UNIT_NOTE,
  DELETE_UNIT_NOTE,
  FETCH_UNIT_NOTES,
  EDIT_UNIT,
  CLEAR_UNIT,

  //LEASE
  FETCH_LEASE,
  CLEAR_ACTIVE_LEASE,
  SET_UNIT_LEASES,
  FETCH_SELECTED_UNIT_LEASES,

  // FETCH_LEASE_DETAILS,
  FETCH_LEASE_NOTES,
  ADD_LEASE_NOTE,
  DELETE_LEASE_NOTE,
  CANCEL_LEASE,
  POST_LEASE,
  MAKE_ACTIVE,
  UPDATE_LEASE,

  //MAINTENANCE REQUEST
  FETCH_ACTIVE_MAINTENANCE_REQUESTS,
  FETCH_ALL_MAINTENACE_CURRENT_LEASE,
  CLOSE_MAINTENANCE,
  CREATE_MAINTENANCE_REQ,
  CLEAR_MAINTENANCE_REQESTS,

  //TENANTS
  FETCH_ALL_TENANTS,
  FETCH_TENANT,
  EDIT_LEASE_TENANT_PROFILE,
  DELETE_TENANT,

  //LEASE REQUEST
  DELETE_LEASE_REQUEST,
} from "./types";

export const selectMaintenanceRequests = (id) => (dispatch) => {
  console.log("LEASE ID", id);
  return dispatch(fetchMaintenanceForLease(id))
    .then((response) => {
      return dispatch(
        sortActiveLeaseMaintenance(response.data.maintenance_requests)
      );
    })
    .then((response) => {
      return dispatch(
        requestSuccess(FETCH_ALL_MAINTENACE_CURRENT_LEASE, response)
      );
    })
    .then((response) =>
      dispatch({ type: LOADING_MAINTENANCE_REQUEST_COMPLETE })
    )
    .catch((error) => dispatch(requestFailed(error)));
};

//PROPERTY ACTIONS

export const getSelectedProperty = (id) => (dispatch) => {
  return dispatch(fetchSelectedProperty(id))
    .then((response) =>
      dispatch(requestSuccess(FETCH_PROPERTY_DETAILS, response.data.property))
    )
    .then((response) => dispatch({ type: LOADING_PROPERTY_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error)));
};

export const getAllUserProperties = () => (dispatch) => {
  return dispatch(fetchUserProperties())
    .then((response) =>
      dispatch(requestSuccess(FETCH_USER_PROPERTIES, response.data.properties))
    )
    .then((response) => dispatch({ type: LOADING_PROPERTY_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error)));
};

export const updatePropertyName = (property) => (dispatch) => {
  return dispatch(updateProperty(property))
    .then((response) =>
      dispatch(requestSuccess(EDIT_PROPERTY, response.data.property))
    )
    .then((response) => dispatch({ type: LOADING_PROPERTY_COMPLETE }))
    .then(() => dispatch(successPopUp("Update Complete")))
    .catch((error) => dispatch(requestFailed(error)));
};

export const selectProperty = (id) => (dispatch, getState) => {
  const { [id]: selectedProperty } = getState().properties.userProperties;

  dispatch(requestSuccess(FETCH_PROPERTY_DETAILS, selectedProperty));
};

export const createNewProperty = (property) => (dispatch) => {
  return dispatch(postNewProperty(property))
    .then((response) =>
      dispatch(requestSuccess(POST_NEW_PROPERTY, response.data.property))
    )
    .then((response) => dispatch({ type: LOADING_PROPERTY_COMPLETE }))
    .then(() => dispatch(successPopUp("New property created")))
    .catch((error) =>
      dispatch(requestFailed(error, LOADING_PROPERTY_COMPLETE))
    );
};

export const removeProperty = (id) => (dispatch) => {
  return dispatch(deleteProperty(id))
    .then((response) => dispatch(requestSuccess(DELETE_PROPERTY, id)))
    .then((response) => dispatch({ type: LOADING_PROPERTY_COMPLETE }))
    .then(() => dispatch(successPopUp("Property deleted")))
    .catch((error) => {
      dispatch(failurePopUp(error.response.data.base));
      dispatch(requestFailed(error, LOADING_PROPERTY_COMPLETE));
    });
};

//UNIT ACTIONS

export const getUserUnits = () => (dispatch) => {
  return dispatch(fetchUserUnits())
    .then((response) =>
      dispatch(requestSuccess(FETCH_USER_UNITS, response.data.units))
    )
    .then((response) => dispatch({ type: LOADING_UNIT_COMPLETE }))
    .catch((error) => requestFailed(error, LOADING_UNIT_COMPLETE));
};

export const editUnit = (unit) => (dispatch) => {
  return dispatch(updateUnit(unit))
    .then((response) => dispatch(requestSuccess(EDIT_UNIT, response.data.unit)))
    .then((response) => dispatch({ type: LOADING_UNIT_COMPLETE }))
    .catch((error) => requestFailed(error, LOADING_UNIT_COMPLETE));
};
//Fetch Unit from API if its not already loaded.
//Then fetch Active lease from API with unit's active_lease_id
// export const selectUnit = (id) => async (dispatch, getState) => {
//   dispatch({ type: LOADING });
//
//   const userUnits = getState().units.userUnits;
//
//   const { [id]: selectedUnit, ...rest } = userUnits;
//   dispatch(requestSuccess(FETCH_UNIT_DETAILS, selectedUnit));
//
//   dispatch(fetchLeaseDetails(selectedUnit.active_lease_id));
// };

export const removeUnit = (id) => (dispatch) => {
  return dispatch(deleteUnit(id))
    .then((response) => dispatch(requestSuccess(DELETE_UNIT, id)))
    .then(() => dispatch(successPopUp("Unit deleted")))

    .then((response) => dispatch({ type: LOADING_UNIT_COMPLETE }))
    .catch((error) => {
      dispatch(requestFailed(error, LOADING_UNIT_COMPLETE));
      throw dispatch(failurePopUp(error.response.data.base));
    });
};

export const getUnitDetails = (id) => (dispatch) => {
  return dispatch(fetchUnitDetails(id))
    .then((response) => {
      dispatch(requestSuccess(FETCH_UNIT_DETAILS, response.data.unit));
    })
    .then((response) => dispatch({ type: LOADING_UNIT_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error, LOADING_UNIT_COMPLETE)));
};

export const clearUnit = () => (dispatch) => {
  dispatch({ type: CLEAR_UNIT });
};

export const clearActiveLease = () => (dispatch) => {
  dispatch({ type: CLEAR_ACTIVE_LEASE });
};

export const getUnitDetailsAndActiveLease = (id) => (dispatch) => {
  return dispatch(fetchUnitDetails(id))
    .then((response) => {
      dispatch(requestSuccess(FETCH_UNIT_DETAILS, response.data.unit));
      return response;
    })
    .then((response) => {
      dispatch({ type: LOADING_UNIT_COMPLETE });
      return response;
    })
    .then((response) => {
      if (response.data.unit.active_lease_id) {
        return dispatch(fetchLeaseDetails(response.data.unit.active_lease_id));
      }
      return dispatch(requestSuccess(FETCH_LEASE, {}));
    })
    .then((response) =>
      dispatch(requestSuccess(FETCH_LEASE, response.data.lease))
    )
    .then(() => dispatch({ type: LOADING_LEASE_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error)));
};

export const createNewUnit = (unit) => (dispatch) => {
  return dispatch(postNewUnit(unit))
    .then((response) =>
      dispatch(requestSuccess(POST_NEW_UNIT, response.data.unit))
    )
    .then(() => dispatch(successPopUp("New unit created")))
    .then(() => dispatch({ type: LOADING_UNIT_COMPLETE }))
    .catch((error) => {
      throw dispatch(requestFailed(error, LOADING_UNIT_COMPLETE));
    });
};

// LEASE ACTIONS
export const createNewLease = (lease) => (dispatch) => {
  return dispatch(postNewLease(lease))
    .then((response) =>
      dispatch(requestSuccess(POST_LEASE, response.data.lease))
    )
    .then(() => dispatch(successPopUp("Lease created")))
    .then(() => dispatch({ type: LOADING_LEASE_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error)));
};

export const getUnitLeases = (unitId) => (dispatch) => {
  return dispatch(fetchUnitLeases(unitId))
    .then((response) =>
      dispatch(requestSuccess(FETCH_SELECTED_UNIT_LEASES, response.data.leases))
    )
    .then((response) => dispatch({ type: LOADING_LEASE_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error, LOADING_LEASE_COMPLETE)));
};

export const getLeaseDetails = (id) => (dispatch) => {
  return dispatch(fetchLeaseDetails(id))
    .then((response) => {
      dispatch(requestSuccess(FETCH_LEASE, response.data.lease));
    })
    .then((response) => dispatch({ type: LOADING_LEASE_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error)));
};
export const makeLeaseActive = (lease) => (dispatch) => {
  return dispatch(createActiveLease(lease))
    .then((response) =>
      dispatch(requestSuccess(MAKE_ACTIVE, response.data.lease))
    )
    .then(() => dispatch(successPopUp("Lease is now active")))
    .then(() => dispatch({ type: LOADING_LEASE_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error)));
};

// export const setLeasesForUnit = (leases) => (dispatch) => {
//   dispatch({ type: LOADING_LEASE });
//   dispatch(requestSuccess(SET_UNIT_LEASES, leases));
//   dispatch({ type: LOADING_LEASE_COMPLETE });
// };

export const terminateLease = (lease) => (dispatch) => {
  return dispatch(cancelActiveLease(lease))
    .then((response) => dispatch(requestSuccess(CANCEL_LEASE, {})))
    .then(() => dispatch(successPopUp("Terminate Lease Success")))
    .then(() => dispatch({ type: LOADING_LEASE_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error)));
};

export const addTenantToLease = (lease) => (dispatch, getState) => {
  return dispatch(updateLease(lease))
    .then((response) =>
      dispatch(requestSuccess(UPDATE_LEASE, response.data.lease))
    )
    .then(() => dispatch({ type: LOADING_LEASE_COMPLETE }))
    .then(() => dispatch(successPopUp("Request to add Tenant sent!")))
    .catch((error) => {
      dispatch(
        failurePopUp("Something went wrong while trying to add your Tenants")
      );

      throw dispatch(requestFailed(error, LOADING_LEASE_COMPLETE));
    });
};

//MAINTENANCE ACTIONS

export const clearMaintenanceRequests = () => (dispatch) => {
  dispatch({ type: CLEAR_MAINTENANCE_REQESTS });
};

export const createNewMaintenance = (maintenance_request) => (dispatch) => {
  return dispatch(postNewMaintenance(maintenance_request))
    .then((response) =>
      dispatch(
        requestSuccess(
          CREATE_MAINTENANCE_REQ,
          response.data.maintenance_request
        )
      )
    )
    .then(() => dispatch(successPopUp("Maintenance request submitted")))
    .then(() => dispatch({ type: LOADING_MAINTENANCE_REQUEST_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error)));
};

export const completeMaintenanceRequest = (data) => (dispatch) => {
  const updatedData = {
    id: data.id,
    active: false,
    date_completed: new Date(),
  };
  return dispatch(updateMaintenanceRequest(updatedData))
    .then((response) =>
      dispatch(
        requestSuccess(CLOSE_MAINTENANCE, response.data.maintenance_request)
      )
    )
    .then(() => dispatch({ type: LOADING_MAINTENANCE_REQUEST_COMPLETE }))
    .then(() => dispatch(successPopUp("Marked as complete!")))
    .catch((error) => dispatch(requestFailed(error)));
};

export const getAllActiveMaintenanceRequests = () => (dispatch) => {
  return dispatch(fetchAllActiveMaintenanceRequests())
    .then((response) =>
      dispatch(
        requestSuccess(
          FETCH_ACTIVE_MAINTENANCE_REQUESTS,
          response.data.maintenance_requests
        )
      )
    )
    .then(() => dispatch({ type: LOADING_MAINTENANCE_REQUEST_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error)));
};

export const filterMaintenanceRequests = () => async (dispatch, getState) => {
  const activeLeases = getState().leases.activeLeases;

  let requests = [];

  Object.values(activeLeases).forEach((lease) => {
    requests.push({
      ...lease,
      maintenance_requests: lease.maintenance_requests.filter(
        (request) => request.active
      ),
    });
  });

  dispatch({
    type: FETCH_ACTIVE_MAINTENANCE_REQUESTS,
    payload: requests,
  });
};

//Filters completed and not completed tickets
export const sortActiveLeaseMaintenance = (maintenanceTix) => (dispatch) => {
  const activeTix = maintenanceTix.filter((tix) => tix.active);
  const completedTix = maintenanceTix.filter((tix) => !tix.active);
  return {
    active: activeTix,
    completed: completedTix,
  };
};

//NOTE ACTIONS

export const getLeaseNotes = (id) => (dispatch) => {
  return dispatch(fetchUnitNotes("lease_id", id))
    .then((response) =>
      dispatch(requestSuccess(FETCH_LEASE_NOTES, response.data.notes))
    )
    .then(() => dispatch({ type: LOADING_NOTES_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error)));
};

export const getUnitNotes = (id) => (dispatch) => {
  return dispatch(fetchUnitNotes("unit_id", id))
    .then((response) =>
      dispatch(requestSuccess(FETCH_UNIT_NOTES, response.data.notes))
    )
    .then(() => dispatch({ type: LOADING_NOTES_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error)));
};

export const createNewNote = (note, type) => (dispatch) => {
  return dispatch(postNewNote(note))
    .then((response) => {
      if (type === "lease_id") {
        return dispatch(requestSuccess(ADD_LEASE_NOTE, response.data.note));
      }
      if (type === "unit_id") {
        return dispatch(requestSuccess(ADD_UNIT_NOTE, response.data.note));
      }
      if (type === "property_id") {
        return dispatch(requestSuccess(ADD_PROPERTY_NOTE, response.data.note));
      }
    })
    .then(() => dispatch({ type: LOADING_NOTES_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error)));
};

export const removeLeaseNote = (id) => (dispatch) => {
  return dispatch(deleteNote(id))
    .then((response) => dispatch(requestSuccess(DELETE_LEASE_NOTE, id)))
    .then(() => dispatch({ type: LOADING_NOTES_COMPLETE }))
    .then(() => dispatch(successPopUp("Note deleted")))
    .catch((error) => dispatch(requestFailed(error)));
};

export const removeUnitNote = (id) => (dispatch) => {
  return dispatch(deleteNote(id))
    .then((response) => dispatch(requestSuccess(DELETE_UNIT_NOTE, id)))
    .then(() => dispatch({ type: LOADING_NOTES_COMPLETE }))
    .then(() => dispatch(successPopUp("Note deleted")))
    .catch((error) => dispatch(requestFailed(error)));
};

export const removePropertyNote = (id) => (dispatch) => {
  return dispatch(deleteNote(id))
    .then((response) => dispatch(requestSuccess(DELETE_PROPERTY_NOTE, id)))
    .then(() => dispatch({ type: LOADING_NOTES_COMPLETE }))
    .then(() => dispatch(successPopUp("Note deleted")))
    .catch((error) => dispatch(requestFailed(error)));
};

//TENANT ACTIONS

export const getAllTenants = () => (dispatch) => {
  return dispatch(fetchAllTenants())
    .then((response) =>
      dispatch(requestSuccess(FETCH_ALL_TENANTS, response.data.tenant_profiles))
    )
    .then(() => dispatch({ type: LOADING_TENANT_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error)));
};

export const getTenant = (id) => (dispatch) => {
  return dispatch(fetchTenant(id))
    .then((response) =>
      dispatch(requestSuccess(FETCH_TENANT, response.data.tenant_profile))
    )
    .then(() => dispatch({ type: LOADING_TENANT_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error)));
};

export const editCustomTenantProfile = (profile) => (dispatch) => {
  return dispatch(updateTenantProfile(profile))
    .then((response) =>
      dispatch(
        requestSuccess(EDIT_LEASE_TENANT_PROFILE, response.data.tenant_profile)
      )
    )
    .then(() => dispatch({ type: LOADING_TENANT_COMPLETE }))
    .then(() => dispatch(successPopUp("Tenant Profile Saved")))
    .catch((error) => dispatch(requestFailed(error)));
};

export const removeCustomTenant = (id) => (dispatch) => {
  return dispatch(deleteTenant(id))
    .then((response) => dispatch(requestSuccess(DELETE_TENANT, id)))
    .then(() => dispatch({ type: LOADING_TENANT_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error)));
};

//Lease Request actions/////////////////
export const revokeLeaseRequest = (id) => (dispatch) => {
  return dispatch(deleteLeaseRequest(id))
    .then((response) => dispatch(requestSuccess(DELETE_LEASE_REQUEST, id)))
    .then(() => dispatch({ type: LOADING_LEASE_REQUEST_COMPLETE }))
    .catch((error) => dispatch(requestFailed(error)));
};
